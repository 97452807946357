import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useSearchParams } from "react-router-dom";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import cashOnPickup from '../../assets/products/cash_on_pickup.png';
import paymentOnDelivery from "../../assets/products/payment_on_delivery.png";
import bulilitBox from "../../assets/products/jrs_bulilit_box.png";
import expressLetter from "../../assets/products/express_letter.png";
import onePounder from "../../assets/products/one_pounder.png";
import threePounder from "../../assets/products/three_pounder.png";
import fivePounder from "../../assets/products/five_pounder.png";
import extraSmallBox from "../../assets/products/extra_small_box.png";
import smallBox from "../../assets/products/small_box.png";
import mediumBox from "../../assets/products/medium_box.png";
import largeBox from "../../assets/products/large_box.png";
import expressCargo from "../../assets/products/express_cargo.png";
import superExpress from "../../assets/products/super_express.png";
import regularLetter from "../../assets/products/regular_letter.png";
import brownEnvelope from "../../assets/products/brown_envelope.png";
import generalCargo from "../../assets/products/general_cargo.png";
import documents from "../../assets/products/documents.png";
import smallParcel from "../../assets/products/small_parcel.png";
import freightForwarder from "../../assets/products/freight_forwarder.png";
import lipatBahay from "../../assets/products/lipat_bahay.png";
import jrsPera from "../../assets/products/jrs_pera.png";

function Services() {
    var products = [
        {
            name: "New Cash on Pickup",
            description: "Ang Cash on Pick-Up service ay laging maaasahan para ligtas ang nais ipadala.",
            image: cashOnPickup,
            domestic: true
        },
        {
            name: "New Payment on Delivery",
            description: "Alternatibong paraan ng pagbabayad ng inyong mga padalang pagmamahal. Gamit ang Payment On Delivery, i-scan lang ang QR Code upang makapagbayad gamit ang GCash o PayMaya.",
            image: paymentOnDelivery,
            domestic: true
        },
        {
            name: "JRS Bulilit Box",
            description: "Minimum charge for 1.5 to 2.5 kilos. Additional charge for succeeding grams.",
            image: bulilitBox,
            domestic: true
        },
        {
            name: "Express Letter",
            description: "Minimum charge for the 1st 100 grams. Additional Charge for succeeding 100 grams.",
            image: expressLetter,
            domestic: true
        },
        {
            name: "1 Pounder",
            description: "Minimum charge for the 1st 500 grams. Additional Charge for succeeding 500 grams.",
            image: onePounder,
            domestic: true
        },
        {
            name: "3 Pounder",
            description: "Minimum charge for the 1st 1,500 grams or 1.5 kilo. Additional Charge for succeeding 500 grams.",
            image: threePounder,
            domestic: true
        },
        {
            name: "5 Pounder",
            description: "Minimum charge for the 1st 2,500 grams or 2.5 kilos. Additional Charge for succeeding 500 grams.",
            image: fivePounder,
            domestic: true
        },
        {
            name: "JRS Extra Small Box",
            description: "Minimum charge for 3 to 4 kilos. Additional charge for succeeding kilo.",
            image: extraSmallBox,
            domestic: true
        },
        {
            name: "Small Box",
            description: "Minimum charge for 4 to 5 kilos. Additional charge for succeeding kilo.",
            image: smallBox,
            domestic: true
        },
        {
            name: "Medium Box",
            description: "Minimum charge for 6 to 9 kilos. Additional charge for succeeding kilo.",
            image: mediumBox,
            domestic: true
        },
        {
            name: "Large Box",
            description: "Minimum charge for 10 to 20 kilos. Additional charge for succeeding kilo.",
            image: largeBox,
            domestic: true
        },
        {
            name: "Express Cargo",
            description: "Minimum Charge for the 1st 2,500 grams. Additional Charge for succeeding 500 grams.",
            image: expressCargo
        },
        {
            name: "Super Express",
            description: "Same day delivery - within locality to be pick-up before 12 noon.",
            image: superExpress
        },
        {
            name: "General Cargo",
            description: "Minimum charge for the 1st 3 kilograms. Additional Charge for succeeding 1000 grams.",
            image: generalCargo,
            domestic: true
        },
        {
            name: "Freight Forwarder",
            description: "Guarantees that products will get to proper destination by agreed upon date and in good condition. Will negotiate the best possible price to make the product along the most economical route.",
            image: freightForwarder,
            domestic: true
        },
        {
            name: "Lipat Bahay",
            description: "Subject for quotation per list of  on items provided by shipper.",
            image: lipatBahay,
            domestic: true
        }
        // {
        //     name: "JRS Pera",
        //     description: "Minimum charge of 6 pesos fee for 100 pesos amount. Additional charge of fee for 101 pesos and above (principle amount)",
        //     image: jrsPera,
        //     domestic: true
        // }
    ];

    let [searchParams, setSearchParams] = useSearchParams();

    const [productList, setProductList] = React.useState([]);

    React.useEffect(() => {
        let getType = searchParams.get("type");

        console.log("type", getType);

        if (getType !== null) {
            if (getType === "domestic") {
                setProductList(products.filter(x => x.domestic === true));
            }
            else {
                setProductList(products.filter(x => x.international === true));
            }
        }
        else {
            setProductList(products);
        }
    }, [])

   

    return (
        <>
            <Header/>

            {/* for mobile */}
            <Box sx={{display: {xs: 'flex', sm:'flex', md: 'none'}, flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'30px'}}>

                <Typography variant="h6" gutterBottom>Our <b>Services</b></Typography>

                <Divider sx={{width:'50px', border:'1px solid #046A38', marginBottom: '0px'}}/>

            </Box>

            <Box sx={{display: {xs: 'none', sm:'none', md: 'flex'}, flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'30px'}}>

                <Typography variant="h6" gutterBottom><b>Our Services</b></Typography>

            </Box>

            
            <Box sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                {productList.map((prod) => (
                    <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', paddingLeft:'10px', paddingRight: '10px'}}>
                        <Box sx={{width:'300px', height:'300px', display:'flex', alignItems:'center', backgroundColor:'#f2f2f2'}}>
                            <img src={prod.image} style={{width:'100%'}}/>
                        </Box>
                        <Box sx={{backgroundColor: '#046A38', padding:'10px', width:'280px', height:'180px', position:'relative', top:'-10px', border:'1px solid #046A38', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', minHeight:'125px'}}>
                            <Typography variant="subtitle1" gutterBottom sx={{fontWeight:'bold', color: '#fff', textAlign: {xs:'center', sm:'center', md:'left'}}}>{prod.name}</Typography>
                            <Typography variant="subtitle1" sx={{color: '#fff', lineHeight:'18px', paddingBottom:'15px'}}>{prod.description}</Typography>
                        </Box>
                    </Box>
                ))}
            </Box>  

            <Divider sx={{width:'100%', marginBottom: '0px', marginTop: '50px'}}/>

            <Footer/>
        </>
    )
}

export default Services;