import React from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import callMobile from '../../assets/call_mobile.png';
import locationMobile from '../../assets/location_mobile.png';
import jrsLogoMobile from '../../assets/logo_mobile.png';
import fbMobile from "../../assets/fb_int_mobile.png";
import ytMobile from "../../assets/yt_int_mobile.png";
import instaMobile from "../../assets/insta_int_mobile.png";
import email from "../../assets/email.png";
import phone from "../../assets/phone.png";
import fb from "../../assets/fb_international.png";
import yt from "../../assets/yt_international.png";
import instagram from "../../assets/instagram.png";

import talkDesktop from "../../assets/talk_desktop.png";
import openDesktop from "../../assets/open_desktop.png";
import visitDesktop from "../../assets/visit_desktop.png";

function InternationalFooter() {
    return (
        <>
            {/* for mobile */}
            <Box sx={{display: { xs:'flex', sm:'flex', md:'none'}, flexDirection:'column', backgroundColor: '#fff', padding:'15px'}}>
                <Box sx={{textAlign:'center', marginBottom:'15px'}}>
                    <img src={jrsLogoMobile} style={{width:'112px'}}/>
                </Box>

                <Box sx={{display:'flex'}}>
                    <Box sx={{display:'flex', flex:1, justifyContent:'center', width:'100%'}}>
                        <Box>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '0px', fontWeight:'bold', fontSize:'10px'}}>Contact Us</Typography>
                            <Box sx={{display:'flex'}}>
                                <img src={email} style={{width:'40px', height:'27px'}}/>
                                <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'', fontSize:'10px'}}>international@jrs-express.com.ph</Typography>
                            </Box>
                            <Box sx={{display:'flex'}}>
                                <img src={phone} style={{width:'30px', height: '30px', paddingTop:'10px', paddingRight:'10px'}}/>
                                <Box>
                                    <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'',fontSize:'10px'}}>(02) 8631 - 7351 to 56 loc. 234</Typography>
                                    <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'',fontSize:'10px'}}>(02) 8634 - 9496</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{display:'flex', flex:1, justifyContent:'center'}}>
                        <Box>
                        <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '0px', fontWeight:'bold', fontSize:'10px'}}>Connect On Social</Typography>
                        <Box sx={{display:'flex', justifyContent:'space-between'}}>
                            <Link href="https://www.facebook.com/JRS.Express.OfficialFanPage" underline="none"><img src={fbMobile} style={{}}/></Link>
                            <Link href="https://instagram.com/jrs.expressph?igshid=MzRlODBiNWFlZA==" underline="none"><img src={instaMobile} style={{marginLeft:'15px'}}/></Link>
                            <Link href="https://www.youtube.com/channel/UCM7Jjp4xRxNML0b_MthM0yg" underline="none"><img src={ytMobile} style={{marginLeft:'15px'}}/></Link>
                        </Box>
                        </Box>
                    </Box>
            </Box>
            <Box>
                <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '0px', fontWeight:'', fontSize:'12px', textAlign:'center', marginTop:'30px'}}>&copy; 2023 - JRS Business Corporation</Typography>
                </Box>
            </Box>

            {/* for non mobile */}
            <Box sx={{display: { xs:'none', sm:'none', md:'flex'},  flexDirection:'column', justifyContent: 'center', padding: '30px', height:'250px'}}>
                <Box sx={{display:'flex'}}>
                    <Box sx={{marginRight:'15px', flex:1}}>
                        <img src={jrsLogoMobile} />
                    </Box>
                    <Box sx={{display:'flex', flex:2, justifyContent:'center', width:'100%'}}>
                        <Box>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '0px', fontWeight:'bold', fontSize:'20px'}}>Contact Us</Typography>
                            <Box sx={{display:'flex'}}>
                                <img src={email} style={{width:'40px', height:'27px'}}/>
                                <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'', fontSize:'20px'}}>international@jrs-express.com.ph</Typography>
                            </Box>
                            <Box sx={{display:'flex'}}>
                                <img src={phone} style={{width:'30px', height: '30px', paddingTop:'10px', paddingRight:'10px'}}/>
                                <Box>
                                    <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'',fontSize:'20px'}}>(02) 8631 - 7351 to 56 loc. 234</Typography>
                                    <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'',fontSize:'20px'}}>(02) 8634 - 9496</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', flex:2, justifyContent:'center'}}>
                        <Box>
                        <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '0px', fontWeight:'bold', fontSize:'20px'}}>Connect On Social</Typography>
                        <Box sx={{display:'flex', justifyContent:'space-between'}}>
                            <Link href="https://www.facebook.com/JRS.Express.OfficialFanPage" underline="none"><img src={fb} style={{}}/></Link>
                            <Link href="https://instagram.com/jrs.expressph?igshid=MzRlODBiNWFlZA==" underline="none"><img src={instagram} style={{marginLeft:'15px'}}/></Link>
                            <Link href="https://www.youtube.com/channel/UCM7Jjp4xRxNML0b_MthM0yg" underline="none"><img src={yt} style={{marginLeft:'15px'}}/></Link>
                        </Box>
                        </Box>
                    </Box>
                </Box>

                <Box>
                <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '0px', fontWeight:'', fontSize:'16px', textAlign:'center', marginTop:'30px'}}>&copy; 2023 - JRS Business Corporation</Typography>
                </Box>
                
            </Box>
            
            
        </>
    )
}

export default InternationalFooter;