import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import SearchIcon from '@mui/icons-material/Search';

import codHeaderDesktop from "../../assets/cod_header_desktop.png";
import codRequirementsDesktop from "../../assets/cod_requirements_desktop.png";
import codDesktop from "../../assets/cod_desktop.png";

import codHeaderMobile from "../../assets/cod_header_mobile.png";
import codRequirementsMobile from "../../assets/cod_requirements_mobile.png";
import codMobile from "../../assets/cod_mobile.png";

function CashOnDelivery() {
    return (
        <>
            <Header />

            {/* for mobile */}
            <Box sx={{display: {xs:'flex',sm:'flex', md:'none'}, flexDirection:'column'}}>
                <Box sx={{display: {xs:'flex',sm:'none', md:'none'}}}>
                    <img src={codHeaderMobile} style={{width:'100%'}} />
                </Box>

                <Box sx={{display: {xs:'none',sm:'flex', md:'none'}}}>
                    <img src={codHeaderDesktop} style={{width:'100%'}} />
                </Box>

                <Box sx={{display:'flex', flexDirection: 'column', padding:'30px', backgroundColor: '#2E683B', borderRadius:'10px', margin:'15px'}}>
                        <Typography variant="h5" sx={{color: '#fff', fontWeight:'bold', fontSize:'30px'}}> Register now in just 3 steps!</Typography>

                        <Typography variant="subtitle1" sx={{color: '#F7DE00', marginTop: '45px'}}>1. Mag-register</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '5px'}}>Bumisita sa pinakamalapit na JRS Express Branch at mag-apply bilang COD seller. Siguraduhing magdala ng dalawang valid ID.</Typography>

                        <Typography variant="subtitle1" sx={{color: '#F7DE00', marginTop: '15px'}}>2. I-track</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '5px'}}>Magpadala at i-track ang mga COD orders gamit ang JRS Client Portal.</Typography>

                        <Typography variant="subtitle1" sx={{color: '#F7DE00', marginTop: '15px'}}>3. I-claim</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '5px', flexGrow:1}}>Sa loob ng tatlo hanggang limang araw, maaari niyo nang i-claim ang payments. Matatanggap ang kabayaran sa anumang napiling banking platform, nang hindi na kailangang bumalik sa branch!</Typography>

                        <Box sx={{textAlign:'center', marginTop: '15px'}}>
                            <Link href="/branches" underline="none"><Button startIcon={<SearchIcon />} sx={{backgroundColor: '#fff', width:'230px', letterSpacing: '0px', color: '#2E683B', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'12px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#fff', border:'1px solid #2E683B', padding: '5px'}}}>Find a near branch near you</Button></Link>
                        </Box>
                </Box>
                <Box sx={{flex:1, display:'flex', flexDirection: 'column', padding:'30px', backgroundColor: '#fff', borderRadius:'10px'}}>
                        <Typography variant="h5" sx={{color: '#000', fontWeight:'bold', fontSize:'30px'}}> Sa JRS Express COD, <br/> Negosyo ay wagi</Typography>
                        <Typography variant="subtitle1" sx={{color: '#000', marginTop: '30px'}}>Naghahanap ng maaasahang ka-partner sa negosyo?</Typography>
                        <Typography variant="subtitle1" sx={{color: '#000', marginTop: '15px'}}>Sa bagong serbisyo ng JRS Express, siguradong mas mapapadali ang pagpapadala ng inyong mga items! Sa loob ng isa hanggang tatlong araw mula nang mag-rehistro sa pinakamalapit na JRS Branch, ay maaari ka nang maging COD Seller at magkaroon ng access sa aming JRS Express COD Portal. Dito puwedeng i-track ang padala at ang halaga ng kabayarang natanggap.</Typography>
                        <Typography variant="subtitle1" sx={{color: '#000', marginTop: '15px', flexGrow:1}}>Ang kabayaran mula sa inyong mga padala ay maaaring i-claim sa loob ng tatlo hanggang limang araw. Ito ay deretso nang idedeposit ng JRS Express sa inyong napiling banking platform. Hindi na kailangang bumalik sa branch para i-remit.</Typography>
                        <Box sx={{textAlign:'center', marginTop: '15px'}}>
                            <Button sx={{backgroundColor: '#2E683B', width:'175px', letterSpacing: '0px', color: '#fff', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'12px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#2E683B', border:'1px solid #2E683B', padding: '5px'}}}>Sign up now!</Button>
                        </Box>
                </Box>

                <Box sx={{display: {xs:'flex', sm:'none', md:'none'}}}>
                    <img src={codRequirementsMobile} style={{width:'100%'}}/>
                </Box>

                <Box sx={{display: {xs:'none', sm:'flex', md:'none'}}}>
                    <img src={codRequirementsDesktop} style={{width:'100%'}}/>
                </Box>

                <Box sx={{flex:1, textAlign:'center', marginTop:'15px'}}>
                    <img src={codMobile} />
                </Box>

                <Box sx={{flex:1, display:'flex', flexDirection: 'column', padding:'30px', backgroundColor: '#2E683B', borderRadius:'10px', margin: '15px'}}>
                        <Typography variant="h5" sx={{color: '#fff', fontWeight:'bold', fontSize:'28px'}}> Paano mag-claim <br/> ng COD payments?</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>1. Mag-log in sa JRS portal gamit ang username at password.</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>2. I-check ang delivery status ng mga padala, ang halaga ng nakolektang kabayaran, ang halaga ng mga pinaproseso pang kabayaran, at ang halaga ng kabayarang maaari nang i-claim/transfer.</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>3. I-click ang "Request for Withdrawal of money."</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>4. Matapos isend ang request, matatanggap niyo na ang kabayaran sa napiling banking channel sa loob ng 3-5 araw.</Typography>

                        <Box sx={{textAlign:'center', marginTop: '15px'}}>
                            <Button sx={{backgroundColor: '#fff', width:'175px', letterSpacing: '0px', color: '#2E683B', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'12px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#fff', border:'1px solid #2E683B', padding: '5px'}}}>Register Now!</Button>
                        </Box>
                    </Box>
            </Box>

            {/* for non mobile */}
            <Box sx={{display: {xs:'none',sm:'none', md:'flex'}, flexDirection:'column'}}>

                <Box sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'end', backgroundImage: `url(${codHeaderDesktop})`, backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat', height:'550px'}}>
                  
                </Box>

                <Box sx={{display:'flex',padding:'45px'}}>
                    <Box sx={{flex:1, display:'flex', flexDirection: 'column', padding:'30px', backgroundColor: '#2E683B', borderRadius:'10px', marginRight:'15px'}}>
                        <Typography variant="h5" sx={{color: '#fff', fontWeight:'bold', fontSize:'30px'}}> Register now in just 3 steps!</Typography>

                        <Typography variant="subtitle1" sx={{color: '#F7DE00', marginTop: '45px'}}>1. Mag-register</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '5px'}}>Bumisita sa pinakamalapit na JRS Express Branch at mag-apply bilang COD seller. Siguraduhing magdala ng dalawang valid ID.</Typography>

                        <Typography variant="subtitle1" sx={{color: '#F7DE00', marginTop: '15px'}}>2. I-track</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '5px'}}>Magpadala at i-track ang mga COD orders gamit ang JRS Client Portal.</Typography>

                        <Typography variant="subtitle1" sx={{color: '#F7DE00', marginTop: '15px'}}>3. I-claim</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '5px', flexGrow:1}}>Sa loob ng tatlo hanggang limang araw, maaari niyo nang i-claim ang payments. Matatanggap ang kabayaran sa anumang napiling banking platform, nang hindi na kailangang bumalik sa branch!</Typography>

                        <Box sx={{textAlign:'left', marginTop: '15px'}}>
                        <Link href="/branches" underline="none"><Button startIcon={<SearchIcon />} sx={{backgroundColor: '#fff', width:'230px', letterSpacing: '0px', color: '#2E683B', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'12px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#fff', border:'1px solid #2E683B', padding: '5px'}}}>Find a near branch near you</Button></Link>
                        </Box>
                    </Box>
                    <Box sx={{flex:1, display:'flex', flexDirection: 'column', padding:'30px', backgroundColor: '#fff', borderRadius:'10px'}}>
                        <Typography variant="h5" sx={{color: '#000', fontWeight:'bold', fontSize:'30px'}}> Sa JRS Express COD, <br/> Negosyo ay wagi</Typography>
                        <Typography variant="subtitle1" sx={{color: '#000', marginTop: '30px'}}>Naghahanap ng maaasahang ka-partner sa negosyo?</Typography>
                        <Typography variant="subtitle1" sx={{color: '#000', marginTop: '15px'}}>Sa bagong serbisyo ng JRS Express, siguradong mas mapapadali ang pagpapadala ng inyong mga items! Sa loob ng isa hanggang tatlong araw mula nang mag-rehistro sa pinakamalapit na JRS Branch, ay maaari ka nang maging COD Seller at magkaroon ng access sa aming JRS Express COD Portal. Dito puwedeng i-track ang padala at ang halaga ng kabayarang natanggap.</Typography>
                        <Typography variant="subtitle1" sx={{color: '#000', marginTop: '15px', flexGrow:1}}>Ang kabayaran mula sa inyong mga padala ay maaaring i-claim sa loob ng tatlo hanggang limang araw. Ito ay deretso nang idedeposit ng JRS Express sa inyong napiling banking platform. Hindi na kailangang bumalik sa branch para i-remit.</Typography>
                        <Box sx={{textAlign:'left', marginTop: '15px'}}>
                            <Button sx={{backgroundColor: '#2E683B', width:'175px', letterSpacing: '0px', color: '#fff', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'12px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#2E683B', border:'1px solid #2E683B', padding: '5px'}}}>Sign up now!</Button>
                        </Box>
                    </Box>
                </Box>

                <Box>
                    <img src={codRequirementsDesktop} style={{width:'100%'}}/>
                </Box>

                <Box sx={{display:'flex',padding:'45px'}}>
                    <Box sx={{flex:1, display:'flex', flexDirection: 'column', padding:'30px', justifyContent:'center', alignItems:'center', backgroundColor: '#F1F1F1', borderRadius:'10px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', marginRight:'0px'}}>
                        <img src={codDesktop} style={{width:'100%'}} />
                    </Box>
                    <Box sx={{flex:1, display:'flex', flexDirection: 'column', padding:'30px', backgroundColor: '#2E683B', borderRadius:'10px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'}}>
                        <Typography variant="h5" sx={{color: '#fff', fontWeight:'bold', fontSize:'28px'}}> Paano mag-claim <br/> ng COD payments?</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>1. Mag-log in sa JRS portal gamit ang username at password.</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>2. I-check ang delivery status ng mga padala, ang halaga ng nakolektang kabayaran, ang halaga ng mga pinaproseso pang kabayaran, at ang halaga ng kabayarang maaari nang i-claim/transfer.</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>3. I-click ang "Request for Withdrawal of money."</Typography>
                        <Typography variant="subtitle1" sx={{color: '#fff', marginTop: '15px'}}>4. Matapos isend ang request, matatanggap niyo na ang kabayaran sa napiling banking channel sa loob ng 3-5 araw.</Typography>

                        <Box sx={{textAlign:'left', marginTop: '15px'}}>
                            <Button sx={{backgroundColor: '#fff', width:'175px', letterSpacing: '0px', color: '#2E683B', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'12px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#fff', border:'1px solid #2E683B', padding: '5px'}}}>Register Now!</Button>
                        </Box>
                    </Box>
                </Box>

            </Box>

            <Footer/>
        </>
    )
}

export default CashOnDelivery;