import React from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import ApiService from "../../services/apiService";

function BranchLocator() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDaeALD9AR7wgkHpGz06MWdCOJaeRl6l98"
    });

    const containerStyle = {
        width: '100%',
        height: '600px'
    };

    let [searchParams, setSearchParams] = useSearchParams();

    const [branches, setBranches] = React.useState([]);
    const [markers, setMarkers] = React.useState([]);
    const [center, setCenter] = React.useState({
        lat: 14.57065,
        lng: 121.05579
    })
    const [selectedBranch, setSelectedBranch] = React.useState("");

    const [map, setMap] = React.useState(null);

    React.useEffect(() => {
        ApiService.getBranches()
        .then(response => response.json())
        .then(json => {
           setBranches(json);

           let markersList = [];
     

           json.forEach(x => {
            markersList.push({
                position: {
                    lat: Number(x.lat.replace("\r", "").replace("\n", "")),
                    lng: Number(x.lon.replace("\r", "").replace("\n", "")),
                },
            })
           });

           setMarkers(markersList);

           let getLoc = searchParams.get("loc");
           if (getLoc !== null) {
            changeCenter(Number(getLoc), json);
           }
           else {
            setSelectedBranch(json[0])
           }
         
         
        })
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const changeCenter = (id, branchesList) => {
        let getBranch = branchesList.find(x => x.id === id);

        if (typeof(getBranch) !== "undefined") {
            console.log("enter here ")
            setCenter({
                lat: Number(getBranch.lat.replace("\r", "").replace("\n", "")),
                lng: Number(getBranch.lon.replace("\r", "").replace("\n", "")),
                        })

            setSelectedBranch(getBranch);
        }
    }

    const changeCenterDropdown = (event, values) => {
        if (values) {
            console.log("values select", values);

            setCenter({
                lat: Number(values.lat.replace("\r", "").replace("\n", "")),
                lng: Number(values.lon.replace("\r", "").replace("\n", "")),
                
            })
    
            setSelectedBranch(values);
        }
     
    }

    return (
        <>
            <Header/>

            <Box sx={{display: {xs: 'flex', sm:'flex', md: 'none'}, flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'30px'}}>

                <Typography variant="h6" gutterBottom>Our <b>Branches</b></Typography>

                <Divider sx={{width:'50px', border:'1px solid #046A38', marginBottom: '0px'}}/>

            </Box>

            <Box sx={{display: {xs: 'none', sm:'none', md: 'flex'}, flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'30px'}}>

                <Typography variant="h6" gutterBottom><b>Our Branches</b></Typography>

            </Box>

            <Box sx={{display: {xs: 'flex', sm:'flex', md:'none'}, justifyContent:'center', marginBottom:'15px'}}>
                <Box sx={{backgroundColor: '#046A38', width:'295px', minHeight:'150px', borderRadius:'3px', padding:'15px'}}>
                        <Typography sx={{color: '#fff', fontWeight: 'bold'}} variant="h6">Over 450 Branches Nationwide.</Typography>
                        <Typography variant="h6" sx={{color: '#fff', fontWeight: 'bold'}}><b>Visit the branch near you!</b></Typography>
         
                        <Autocomplete
                                disablePortal
                                fullWidth
                                id="combo-box-demo"
                                options={branches}
                                getOptionLabel={option => option.name}
                                onChange={changeCenterDropdown}
                                value={selectedBranch}
                                sx={{  backgroundColor: '#fff' }}
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                                         {selectedBranch && (
                        <Box sx={{backgroundColor: '#fff', padding: '10px', marginTop: '10px'}}>
                        
                            <Typography><b>Branch:</b> JRS {selectedBranch.name}</Typography>
                            <Typography><b>Address:</b> {selectedBranch.name === 'Dubai, UAE (International)' ? 'Shop 2 Hessa 10 Bldg. Satwa Dubai, UAE, Al Satwa, United Arab Emirates' : selectedBranch.address}</Typography>
                            <Typography><b>Contact Person:</b> {selectedBranch.contactPerson}</Typography>
                            <Typography><b>Contact No.:</b> {selectedBranch.contactNumbers}</Typography>
                        </Box>
                    )} 
                </Box>
            </Box>

            {isLoaded && markers.length > 0 ? (
                <Box sx={{position:'relative'}}>
                    <Box sx={{display: {xs: 'none', sm:'none', md:'flex'}}}>
                        <Box sx={{backgroundColor: '#046A38', width:'400px', minHeight:'150px', borderRadius:'3px', padding:'15px', position:'absolute', zIndex:1000, top:'100px', left:'50px'}}>
                            <Typography sx={{color: '#fff', fontWeight: 'bold'}} variant="h6">Over 450 Branches Nationwide.</Typography>
                            <Typography variant="h6" sx={{color: '#fff', fontWeight: 'bold'}}><b>Visit the branch near you! </b></Typography>

                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="combo-box-demo"
                                options={branches}
                                getOptionLabel={option => option.name}
                                onChange={changeCenterDropdown}
                                value={selectedBranch}
                                 sx={{  backgroundColor: '#fff' }}
                                renderInput={(params) => <TextField {...params} label="" />}
                                
                            />
                                    {selectedBranch && (
                        <Box sx={{backgroundColor: '#fff', padding: '10px', marginTop: '10px'}}>
                        
                            <Typography><b>Branch:</b> JRS {selectedBranch.name}</Typography>
                            <Typography><b>Address:</b> {selectedBranch.name === 'Dubai, UAE (International)' ? 'Shop 2 Hessa 10 Bldg. Satwa Dubai, UAE, Al Satwa, United Arab Emirates' : selectedBranch.address}</Typography>
                            <Typography><b>Contact Person:</b> {selectedBranch.contactPerson}</Typography>
                            <Typography><b>Contact No.:</b> {selectedBranch.contactNumbers}</Typography>
                        </Box>
                    )}                            
                        </Box>
                   
                    </Box>

                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                        onUnmount={onUnmount}
                        options={{ styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }], }}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        {markers.map((marker) => (
                            <MarkerF position={marker.position}/>
                        ))}
                        <></>
                    </GoogleMap>
                </Box>
            ) : <></>}

            <Footer/>
        </>
    )
}

export default BranchLocator;