import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { CircularProgress } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import rateBackground from "../../assets/rate_backgound_desktop.png";

import ApiService from "../../services/apiService";

function Rates() {

    const [isLoading, setIsLoading] = React.useState(false);

    const [rateResults, setRateResults] = React.useState(null);
    const [containerTypes, setContainerTypes] = React.useState(["Documents", "Pouch", "Box", "Cargo"]);
    const [weightToggleOptions, setWeightToggleOptions] = React.useState(["Kg", "g"]);
    const [sizeOptions, setSizeOptions] = React.useState(["inch", "cm"]);
    const [provinces, setProvinces] = React.useState([]);

    const [from, setFrom] = React.useState("");
    const [to, setTo] = React.useState("");
    const [weightToggle, setWeightToggle] = React.useState("g");
    const [sizeToggleLength, setSizeToggleLength] = React.useState("cm");
    const [sizeToggleWidth, setSizeToggleWidth] = React.useState("cm");
    const [sizeToggleHeight, setSizeToggleHeight] = React.useState("cm");
    const [length, setLength] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [actualWeight, setActualWeight] = React.useState("");
    const [containerType, setContainerType] = React.useState("");
    const [isExpress, setIsExpress] = React.useState(false);
    const [withInsurance, setWithInsurance] = React.useState(false);
    const [withValue, setWithValue] = React.useState(false);
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        setProvinces([
            "Abra", "Agusan del Norte", "Agusan del Sur", "Aklan", "Albay", "Antique", "Apayao", "Aurora", "Basilan", "Bataan",
            "Batanes", "Batangas", "Benguet", "Biliran", "Bohol", "Bukidnon", "Bulacan", "Cagayan", "Camarines Norte", 
            "Camarines Sur", "Camiguin", "Capiz", "Catanduanes", "Cavite", "Cebu", "Compostela Valley", "Davao del Norte",
            "Davao del Sur", "Davao Oriental", "Dinagat Islands", "Eastern Samar", "Guimaras", "Ifugao", "Ilocos Norte",
            "Ilocos Sur", "Iloilo", "Isabela", "Kalinga", "La Union", "Laguna", "Lanao del Norte", "Lanao del Sur", "Leyte",
            "Maguindanao", "Marinduque", "Masbate", "Metro Manila", "Misamis Occidental", "Misamis Oriental", "Mountain Province",
            "Negros Occidental", "Negros Oriental", "North Cotabato", "Northern Samar", "Nueva Ecija", "Nueva Vizcaya", 
            "Occidental Mindoro", "Oriental Mindoro", "Palawan", "Pampanga", "Pangasinan", "Quezon", "Quirino", "Rizal", 
            "Romblon", "Samar", "Sarangani", "Shariff Kabunsuan", "Siquijor", "Sorsogon", "South Cotabato", "Southern Leyte",
            "Sultan Kudarat", "Sulu", "Surigao del Norte", "Surigao del Sur", "Tarlac", "Tawi-Tawi", "Zambales", 
            "Zamboanga del Norte", "Zamboanga del Sur", "Zamboanga Sibugay"
        ]);
    }, [])

    const calculateRate = () => {
        setIsLoading(true);

        let selectedLength = sizeToggleHeight === "cm" ? Number(length) : Number(length) * 2.54;
        let selectedWidth = sizeToggleHeight === "cm" ? Number(width) : Number(width) * 2.54;
        let selectedHeight = sizeToggleHeight === "cm" ? Number(height) : Number(height) * 2.54;

        let selectedActualWeight = Number(actualWeight);
        if (weightToggle === "Kg") {
            selectedActualWeight = Number(selectedActualWeight) * 1000;
        }

        let model = {
                "apiClientId": "0",
                "accuracy": "estimate",
                "pickupLocation": from + " Province",
                "destinationAddress": to + " Province",
                "destinationProvince": to,
                "originProvince": from,
                "destinationCountry": "Philippines",
                "destinationZipCode": "",
                "containerType": containerType,
                "isExpress": isExpress,
                "customerId": "0",
                "requestItems": [
                    {
                        "declaredValue": Number(value),
                        "length": containerType === "Documents" ? 0 : Number(selectedLength),
                        "width": Number(selectedWidth),
                        "height": Number(selectedHeight),
                        "weight": Number(selectedActualWeight),
                        "itemDescription": ""
                    }
                ],
                "withInsurance": withInsurance,
                "withValuation": withValue
            };

        ApiService.calculateRate(model)
            .then(response => response.json())
            .then(json => {
                console.log("response", json)
                let results = [
                    {
                        name: "Product",
                        cost: json.name
                    },
                    {
                        name: "Base Rate",
                        cost: Number(json.baseRate)
                    },
                    {
                        name: "Insurance",
                        cost: Number(json.insurance)
                    },
                    {
                        name: "Valuation",
                        cost: Number(json.valuation)
                    },
                    {
                        name: "Excess",
                        cost: Number(json.excess)
                    },
                    {
                        name: "Other Charges",
                        cost: Number(json.otherCharges)
                    },
                    {
                        name: "Total Shipping Rate",
                        cost: Number(json.totalShippingRate)
                    }
                ];

                setRateResults(results);
                setIsLoading(false);
            })
    }

    
    return (
        <>
            <Header />
            
            {/* for mobile */}
            <Box sx={{display: {xs: 'flex', sm:'flex', md:'none'}, flexDirection: 'column', padding:'30px'}}>
                <Typography variant="h6" gutterBottom><b>Rate</b> Calculator</Typography>

                <Divider sx={{border: '1px solid #046A38', width:'50px', marginBottom: '30px'}}/>

                <Typography variant="h6" gutterBottom>Tell us about your location.</Typography>

                <FormControl fullWidth sx={{marginTop:'15px'}}>
                    <InputLabel id="from-label">From</InputLabel>
                    <Select
                    labelId="from"
                    id="from"
                    label="From"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    >
                   {provinces.map((row) => (
                                        <MenuItem value={row}>{row}</MenuItem>
                                    ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{marginTop:'15px', marginBottom: '30px'}}>
                    <InputLabel id="to-label">To</InputLabel>
                    <Select
                    labelId="to"
                    id="to"
                    label="To"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    >
                   {provinces.map((row) => (
                                        <MenuItem value={row}>{row}</MenuItem>
                                    ))}
                    </Select>
                </FormControl>

                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Container</Typography>
                    </Box>
                    <Box sx={{flex:2}}>
                        <FormControl fullWidth sx={{}}>
                            <InputLabel id="to-label">Container Type</InputLabel>
                            <Select
                            labelId="to"
                            id="to"
                            label="Container Type"
                            value={containerType}
                            onChange={(e) => setContainerType(e.target.value)}
                            >
                            {containerTypes.map((row) => (
                                <MenuItem value={row}>{row}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Express</Typography>
                    </Box>
                    <Box sx={{flex:2}}>
                        <Checkbox sx={{paddingLeft:'0px','&.Mui-checked': {color: '#046A38'}}} value={isExpress} onChange={(e) => setIsExpress(e.target.checked)}/>
                    </Box>
                </Box>
            
                {containerType !== "Documents" ? (
                    <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                        <Box sx={{flex:1}}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Length</Typography>
                        </Box>
                        <Box sx={{flex:2}}>
                            <TextField id="outlined-basic" label="" placeholder="" fullWidth variant="outlined" value={length} onChange={(e) => setLength(e.target.value)} type="number"/>
                        </Box>
                    </Box>
                ) : ""}
              

                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Width</Typography>
                    </Box>
                    <Box sx={{flex:2}}>
                        <TextField id="outlined-basic" label="" placeholder="" fullWidth variant="outlined" value={width} onChange={(e) => setWidth(e.target.value)} type="number"/>
                    </Box>
                  
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Height</Typography>
                    </Box>
                    <Box sx={{flex:1}}>
                        <TextField id="outlined-basic" label="" placeholder="" fullWidth variant="outlined" value={height} onChange={(e) => setHeight(e.target.value)} type="number"/>
                    </Box>
                    <Box sx={{flex:1}}>
                        <FormControl fullWidth sx={{marginLeft:'10px', flex:1}}>
                            <InputLabel id="to-label"></InputLabel>
                            <Select
                            labelId="to"
                            id="to"
                            label=""
                            sx={{marginRight:'15px'}}
                            value={sizeToggleHeight}
                            onChange={(e) => setSizeToggleHeight(e.target.value)}
                            >
                            {sizeOptions.map((row) => (
                                <MenuItem value={row}>{row}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Actual Weight</Typography>
                    </Box>
                    <Box sx={{flex:1}}>
                        <TextField id="outlined-basic" label="" placeholder="" fullWidth variant="outlined" value={actualWeight} onChange={(e) => setActualWeight(e.target.value)} type="number"/>
                    </Box>
                    <Box sx={{flex:1}}>
                        <FormControl fullWidth sx={{marginLeft:'10px', flex:1}}>
                            <InputLabel id="to-label"></InputLabel>
                            <Select
                            labelId="to"
                            id="to"
                            label=""
                            sx={{marginRight:'15px'}}
                            value={weightToggle}
                            onChange={(e) => setWeightToggle(e.target.value)}
                            >
                            {weightToggleOptions.map((row) => (
                                                    <MenuItem value={row}>{row}</MenuItem>
                                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Typography variant="h6" gutterBottom sx={{marginTop:'45px'}}>Additional Charges.</Typography>

                <Box sx={{display:'flex', alignItems: 'center'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Insurance</Typography>
                    </Box>
                    <Box sx={{flex:2}}>
                        <Checkbox sx={{paddingLeft:'0px','&.Mui-checked': {color: '#046A38'}}} value={withInsurance} onChange={(e) => setWithInsurance(e.target.checked)}/>
                    </Box>
                </Box>

                <Box sx={{display:'flex', alignItems: 'center'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Valuation</Typography>
                    </Box>
                    <Box sx={{flex:2}}>
                        <Checkbox sx={{paddingLeft:'0px','&.Mui-checked': {color: '#046A38'}}} value={withValue} onChange={(e) => setWithValue(e.target.checked)}/>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                    <Box sx={{flex:1}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Value</Typography>
                    </Box>
                    <Box sx={{flex:2}}>
                        <TextField id="outlined-basic" label="" placeholder="" variant="outlined" fullWidth type="number" value={value} onChange={(e) => setValue(e.target.value)}/>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', marginTop: '30px', marginBottom: '30px'}}>
                    <Button sx={{backgroundColor: '#046A38', color: '#fff', fontWeight:'bold', width:'175px', height:'70px', '&:hover': {backgroundColor: '#046A38', color: '#fff'}}} onClick={calculateRate}>Calculate Rate</Button>
                    <Box sx={{display:'flex', justifyContent:'start', marginTop: '15px'}}>
                        <Typography>*Promos and Discounts will be applied in-store</Typography>
                    </Box>
                </Box>

                {isLoading ? (
                    <Box sx={{textAlign:'center'}}>
                        <CircularProgress sx={{color: '#046A38'}}/>
                    </Box>
                ) : ("")}

                {rateResults !== null ? (
                    <>
                        <TableContainer component={'div'}>
                            <Table aria-label="custom pagination table" size="small" >
                                <TableBody>
                                    {rateResults.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ border:'1px solid #000' }}
                                        >
                                            <TableCell component="th" scope="row" sx={{borderBottom: '1px solid #000',borderRight: '1px solid #000',  fontWeight: row.name === "Total Shipping Rate" ? "bold" : ""}}>
                                            {row.name}
                                            </TableCell>
                                            <TableCell align="right" sx={{borderBottom: '1px solid #000',  fontWeight: row.name === "Total Shipping Rate" ? "bold" : ""}}>{row.cost}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : ("")}
            </Box>

            {/* for non mobile */}
            <Box sx={{display: {xs: 'none', sm:'none', md:'flex'}, flexDirection: 'column'}}>
                <Box sx={{textAlign:'center',marginTop:'30px', marginBottom: '30px'}}>
                    <Typography variant="h6" gutterBottom><b>Rate Calculator</b></Typography>
                </Box>

                <Box sx={{display: {xs: 'none', sm:'none', md:'flex'}, flexDirection: 'column', justifyContent: 'center', alignItems:'center', backgroundImage: `url(${rateBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight:'1350px'}}>
                    <Box sx={{display:'flex', flexDirection: 'column', width: '75%', backgroundColor: '#fff', height:'75%'}}>
                        <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                            <Box sx={{width: '75%', padding:'30px', paddingBottom:'0px'}}>
                                <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Tells us about the location.</Typography>
                                <FormControl fullWidth sx={{marginTop:'15px'}}>
                                    <InputLabel id="from-label">From</InputLabel>
                                    <Select
                                    labelId="from"
                                    id="from"
                                    label="From"
                                    value={from}
                                    onChange={(e) => setFrom(e.target.value)}
                                    >
                                  {provinces.map((row) => (
                                        <MenuItem value={row}>{row}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{marginTop:'15px', marginBottom: '30px'}}>
                                    <InputLabel id="to-label">To</InputLabel>
                                    <Select
                                    labelId="to"
                                    id="to"
                                    label="To"
                                    value={to}
                                    onChange={(e) => setTo(e.target.value)}
                                    >
                                    {provinces.map((row) => (
                                        <MenuItem value={row}>{row}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Divider sx={{width:'95%'}} />

                            <Box sx={{width: '75%', padding:'30px', paddingBottom:'0px', display:'flex', flexDirection:'column'}}>
                                <Box sx={{display:'flex', justifyContent:'space-around', marginTop:'15px', marginBottom:'15px'}}>
                                    <Box sx={{flex:1}}>
                                        <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold', marginRight:'15px'}}>Container Type</Typography>
                                        <FormControl fullWidth sx={{}}>
                                            <InputLabel id="to-label">Container Type</InputLabel>
                                            <Select
                                            labelId="to"
                                            id="to"
                                            label="Container Type"
                                            value={containerType}
                                            onChange={(e) => setContainerType(e.target.value)}
                                            >
                                            {containerTypes.map((row) => (
                                                <MenuItem value={row}>{row}</MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{flex:1, alignItems: 'center', marginLeft: '30px'}}>
                                        <Box sx={{flex:1}}>
                                        <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold', marginRight:'15px'}}>Express</Typography>
                                        </Box>
                                        <Box sx={{flex:2}}>
                                            <Checkbox sx={{paddingLeft:'0px','&.Mui-checked': {color: '#046A38'}}} value={isExpress} onChange={(e) => setIsExpress(e.target.checked)}/>
                                        </Box>
                                    </Box>
                                </Box>  
                                <Box sx={{display:'flex', justifyContent:'space-around'}}>
                                    {containerType !== "Documents" ? (
                                        <Box sx={{flex:1, paddingRight:'15px'}}>
                                            <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Length</Typography>
                                            <Box sx={{display:'flex', alignItems:'center'}}>
                                                <TextField id="outlined-basic" label="Length" type="number" variant="outlined" sx={{width:'100%', flex:2}} value={length} onChange={(e) => setLength(e.target.value)}/>
                                               
                                            </Box>
                                        </Box>
                                    ) : ""}
                                    <Box sx={{flex:1, paddingRight:'15px'}}>
                                        <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Width</Typography>
                                        <Box sx={{display:'flex', alignItems:'center'}}>
                                            <TextField id="outlined-basic" label="Width" type="number" variant="outlined" sx={{width:'100%', flex:2}} value={width} onChange={(e) => setWidth(e.target.value)}/>
                                          
                                        </Box>
                                    </Box>

                                    <Box sx={{flex:1}}>
                                        <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Height</Typography>
                                        <Box sx={{display:'flex', alignItems:'center'}}>
                                            <TextField id="outlined-basic" label="Height" type="number" variant="outlined" sx={{width:'100%', flex:2}} value={height} onChange={(e) => setHeight(e.target.value)}/>
                                            <FormControl fullWidth sx={{marginLeft:'10px', flex:1}}>
                                                <InputLabel id="to-label"></InputLabel>
                                                <Select
                                                labelId="to"
                                                id="to"
                                                label=""
                                                value={sizeToggleHeight}
                                                onChange={(e) => setSizeToggleHeight(e.target.value)}
                                                >
                                                {sizeOptions.map((row) => (
                                                    <MenuItem value={row}>{row}</MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'start', marginTop:'15px', marginBottom:'15px'}}>
                                    <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold', marginRight:'15px'}}>Actual Weight</Typography>
                                    <Box sx={{display:'flex', alignItems:'center', width:'75%'}}>
                                            <TextField id="outlined-basic" label="Actual Weight" type="number" variant="outlined" sx={{width:'100%', flex:5}} value={actualWeight} onChange={(e) => setActualWeight(e.target.value)}/>
                                            <FormControl fullWidth sx={{marginLeft:'10px', flex:1}}>
                                                <InputLabel id="to-label"></InputLabel>
                                                <Select
                                                labelId="to"
                                                id="to"
                                                label=""
                                                value={weightToggle}
                                                onChange={(e) => setWeightToggle(e.target.value)}
                                                >
                                                {weightToggleOptions.map((row) => (
                                                    <MenuItem value={row}>{row}</MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                </Box>  
                              
                            </Box>

                           

                         

                            <Divider sx={{width:'95%'}} />

                            <Box sx={{width: '75%', padding:'30px'}}>
                                <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Additional charges.</Typography>
                               
                                <Box sx={{display:'flex', alignItems: 'center'}}>
                                    <Box sx={{flex:1}}>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', color: '#046A38'}}>Insurance</Typography>
                                    </Box>
                                    <Box sx={{flex:2}}>
                                        <Checkbox sx={{paddingLeft:'0px','&.Mui-checked': {color: '#046A38'}}} value={withInsurance} onChange={(e) => setWithInsurance(e.target.checked)}/>
                                    </Box>
                                </Box>

                                <Box sx={{display:'flex', alignItems: 'center'}}>
                                    <Box sx={{flex:1}}>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', color: '#046A38'}}>Valuation</Typography>
                                    </Box>
                                    <Box sx={{flex:2}}>
                                        <Checkbox sx={{paddingLeft:'0px','&.Mui-checked': {color: '#046A38'}}} value={withValue} onChange={(e) => setWithValue(e.target.checked)}/>
                                    </Box>
                                </Box>

                                <Box sx={{display:'flex', alignItems: 'center'}}>
                                    <Box sx={{flex:1}}>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', color: '#046A38'}}>Value</Typography>
                                    </Box>
                                    <Box sx={{flex:2}}>
                                    <TextField id="outlined-basic" label="" placeholder="0" variant="outlined" fullWidth value={value} onChange={(e) => setValue(e.target.value)} type="number"/>
                                    </Box>
                                </Box>
                            </Box>

                            <Divider sx={{width:'95%'}} />

                            <Box sx={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', marginTop: '30px', marginBottom: '0px'}}>
                                <Button sx={{backgroundColor: '#046A38', color: '#fff', fontWeight:'bold', width:'175px', height:'70px', marginBottom:'15px', '&:hover': {backgroundColor: '#046A38', color: '#fff'}}} onClick={calculateRate}>Calculate Rate</Button>
                                <Box sx={{display:'flex', justifyContent:'start', marginBottom:'15px'}}>
                                    <Typography>*Promos and Discounts will be applied in-store</Typography>
                                </Box>
                            </Box>

                            {isLoading ? (
                                <Box sx={{textAlign:'center', marginTop:'15px'}}>
                                    <CircularProgress sx={{color: '#046A38'}}/>
                                </Box>
                            ) : ("")}

                            {rateResults !== null ? (
                                <>
                                    <TableContainer component={'div'} sx={{width:'80%', marginBottom:'30px'}}>
                                        <Table aria-label="custom pagination table" size="small">
                                            <TableBody>
                                                {rateResults.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ border:'1px solid #000' }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{borderBottom: '1px solid #000',borderRight: '1px solid #000', fontWeight: row.name === "Total Shipping Rate" ? "bold" : ""}}>
                                                        {row.name}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{borderBottom: '1px solid #000', fontWeight: row.name === "Total Shipping Rate" ? "bold" : ""}}>{row.cost}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography sx={{marginTop:'15px', marginLeft:'30px', marginRight: '30px', fontStyle: 'italic'}}>*The shipping rate calculator is intended to offer estimated shipping costs based on the information you provide. Please note that these estimates are subject to change and may not reflect the final shipping charges. Actual shipping costs may vary depending on factors such as package dimensions, weight, destination, shipping method, and any additional services or fees.</Typography>
                                </>
                            ) : ("")}
                        </Box>
                    </Box>
                </Box>
            </Box>
           

            <Divider sx={{width:'100%'}} />

            <Footer />
        </>
    ) 
}

export default Rates;