import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Header from "../../components/header/header";
import InternationalFooter from "../../components/internationalFooter/internationalFooter";

import internationalBanner from "../../assets/international_banner.png";
import internationalBody from "../../assets/international_body.png";
import internationalFindBranch from "../../assets/international_find_branch.png";

import internationalBannerMobile from "../../assets/international_banner_mobile.png";
import internationalFindBranchMobile from "../../assets/international_find_branch_mobile.png";

function JrsInternational() {
    return (
        <>
            <Header />

            <Box sx={{display:{xs:'flex', sm:'flex', md:'none'}, flexDirection:'column'}}>
                <Box>
                    <img src={internationalBannerMobile} style={{width:'100%'}}/>
                </Box>

                <Box sx={{position:'relative', top:'-5px'}}>
                    <img src={internationalBody} style={{width:'100%'}}/>
                    <Box sx={{textAlign:'center', position:'absolute', bottom:'20px', width:'100%'}}>
                        <Box sx={{width:'100%', textAlign:'center'}}>
                        <Link href="/branches"><img src={internationalFindBranchMobile} style={{}}/></Link>
                        </Box>
                        
                    </Box>
                   
                </Box>
            </Box>

            <Box sx={{display:{xs:'none', sm:'none', md:'flex'}, flexDirection:'column'}}>
                <Box>
                    <img src={internationalBanner} style={{width:'100%'}}/>
                </Box>

                <Box sx={{position:'relative', top:'-5px'}}>
                    <img src={internationalBody} style={{width:'100%'}}/>
                    <Box sx={{textAlign:'center', position:'absolute', bottom:'20px', width:'100%'}}>
                        <Box sx={{width:'100%', textAlign:'center'}}>
                        <Link href="/branches"><img src={internationalFindBranch} style={{}}/></Link>
                        </Box>
                        
                    </Box>
                   
                </Box>
            </Box>


            <InternationalFooter/>
          
        </>
    )
}

export default JrsInternational;