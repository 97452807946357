import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import SearchIcon from '@mui/icons-material/Search';

import podHeaderDesktop from "../../assets/send_court_documents_header.png";
import sendCourtBranch from "../../assets/send_court_branch.png";
import sendCourtGreenPouch from "../../assets/send_court_green_pouches.png";
import sendCourtReminders from "../../assets/send_court_documents_reminders.png";
import sendCourtTerms from "../../assets/send_court_terms.png";
import sendCourtRates from "../../assets/send_court_rates.png";
import sendCourtRatesTable from "../../assets/send_court_rates_table.png";
import podInfoDesktop from "../../assets/pod_info_desktop.png";
import podProcessDesktop from "../../assets/pod_process_desktop.png";

import podHeaderMobile from "../../assets/cat_header_mobile.png";
import catFindBranchMobile from "../../assets/cat_find_branch_mobile.png";
import catServicesMobile from "../../assets/cat_services_mobile.png";
import catRemindersMobile from "../../assets/cat_reminders_mobile.png";
import catTermsMobile from "../../assets/cat_terms_mobile.png";
import catRatesChartMobile from "../../assets/cat_rates_chart_mobile.png";
import catRatesMobile from "../../assets/cat_rates_mobile.png";
import podInfoMobile from "../../assets/pod_info_mobile.png";
import podMaskMobile from "../../assets/pod_maskgroup_mobile.png";
import podProcessMobile from "../../assets/pod_process_mobile.png";

function SendCourtDocuments() {
    return (
        <>
            <Header />

             {/* for mobile */}
        <Box sx={{display: {xs:'flex', sm:'flex', md:'none', flexDirection:'column'}}}>
             <img src={podHeaderMobile} style={{width:'100%'}}/>

             <Link href="/branches">
                <Box sx={{cursor:'pointer', padding:'5px',display: {xs:'flex', sm:'flex', md:'none'}}}>
                    <img src={catFindBranchMobile} style={{width:'100%'}}/>
                </Box>
            </Link>

            <Box>
                <img src={catServicesMobile} style={{width:'100%'}}/>
            </Box>

            <Box sx={{padding:'5px'}}>
                <img src={catRemindersMobile} style={{width:'100%'}}/>
            </Box>

            <Box sx={{ paddingTop:'30px', position:'relative', backgroundColor: '#046A3A', textAlign:'center'}}>
                    <img src={catTermsMobile} style={{width:'90%', marginBottom:'30px'}} />
                    <img src={sendCourtRatesTable} style={{width:'90%', marginBottom:'30px'}} />
                </Box>

           
        </Box>

            {/* for non mobile */}
            <Box sx={{display:{xs:'none', sm:'none', md:'flex'}, flexDirection:'column'}}>
                <Box>
                    <img src={podHeaderDesktop} style={{width:'100%'}}/>
                </Box>

                <Link href="/branches">
                    <Box sx={{cursor:'pointer', padding:'30px'}}>
                        <img src={sendCourtBranch} style={{width:'100%'}}/>
                    </Box>
                </Link>

                <Box>
                    <img src={sendCourtGreenPouch} style={{width:'100%'}}/>
                </Box>

                <Box sx={{padding:'15px'}}>
                    <img src={sendCourtReminders} style={{width:'100%'}}/>
                </Box>

                <Box sx={{margin:'15px', paddingTop:'77px', position:'relative', backgroundColor: '#046A3A', textAlign:'center'}}>
                    <img src={sendCourtTerms} style={{width:'75%', marginBottom:'80px'}} />
                    <img src={sendCourtRatesTable} style={{width:'75%', marginBottom:'30px'}} />
                </Box>
              
            </Box>

            <Footer />
        
        </>
    )
}

export default SendCourtDocuments;