import React from "react";
import { useLocation } from 'react-router-dom'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";



function Privacy() {

    const [readMoreModalOpen, setReadMoreModalOpen] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
    }, [location.pathname]);

    const handleClose = () => {
        setReadMoreModalOpen(false);
    }

    const openReadMoreModal = () => {
        setReadMoreModalOpen(true);
    }

    return (
        <>
            <Header />

            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column' }}>

            <Box sx={{ textAlign: 'center', padding: '30px' }} id="profile">
                    <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>JRS Express Privacy Policy</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ width: '90%' }}>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                                Last updated: [January 2024]
                            </Typography>
                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Introduction
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                                Welcome to the JRS Express Privacy Policy. This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our website or services.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                By using JRS Express, you agree to the collection and use of information in accordance with this policy. If you do not agree with this policy, please refrain from using our website and services.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Information We Collect
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                Personal Information
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may collect personal information such as your name, address, email address, and phone number when you interact with our website or use our services. This information is collected for the purpose of providing and improving our services.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Usage Data
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may also collect information on how our website and services are accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Use of Data
                            </Typography>


                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                JRS Express uses the collected data for various purposes:


                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    To provide and maintain our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To notify you about changes to our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To provide customer support
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To gather analysis or valuable information so that we can improve our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To monitor the usage of our services
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Disclosure of Data
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may disclose your personal information in good faith belief that such action is necessary to:


                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    Comply with a legal obligation
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Protect and defend the rights or property of JRS Express
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Prevent or investigate possible wrongdoing in connection with our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Protect the personal safety of users of our services or the public
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Security of Data
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Changes to This Privacy Policy
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Contact Us
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                If you have any questions about this Privacy Policy, please contact us:


                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    By email: jrs_dpo@jrs-express.com.ph
                                </Typography>
                                <Typography component="li" variant="body1">
                                    By phone: (02) 8631-7351 to 56 ; (02) 8637-2063 to 66
                                </Typography>
                                <Typography component="li" variant="body1">
                                    By address: #19 Brixton Street. Brgy. Kapitolyo Pasig City
                                </Typography>

                            </ul>
                        </Box>
                    </Box>
                </Box>

              


            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, flexDirection: 'column' }}>

                <Box sx={{ textAlign: 'center', padding: '30px' }} id="profile">
                    <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>JRS Express Privacy Policy</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ width: '90%' }}>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                                Last updated: [January 2024]
                            </Typography>
                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Introduction
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                                Welcome to the JRS Express Privacy Policy. This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our website or services.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                By using JRS Express, you agree to the collection and use of information in accordance with this policy. If you do not agree with this policy, please refrain from using our website and services.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Information We Collect
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                Personal Information
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may collect personal information such as your name, address, email address, and phone number when you interact with our website or use our services. This information is collected for the purpose of providing and improving our services.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Usage Data
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may also collect information on how our website and services are accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Use of Data
                            </Typography>


                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                JRS Express uses the collected data for various purposes:


                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    To provide and maintain our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To notify you about changes to our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To provide customer support
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To gather analysis or valuable information so that we can improve our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    To monitor the usage of our services
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Disclosure of Data
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may disclose your personal information in good faith belief that such action is necessary to:


                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    Comply with a legal obligation
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Protect and defend the rights or property of JRS Express
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Prevent or investigate possible wrongdoing in connection with our services
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Protect the personal safety of users of our services or the public
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Security of Data
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Changes to This Privacy Policy
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                Contact Us
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal' }}>
                                If you have any questions about this Privacy Policy, please contact us:


                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    By email: jrs_dpo@jrs-express.com.ph
                                </Typography>
                                <Typography component="li" variant="body1">
                                    By phone: (02) 8631-7351 to 56 ; (02) 8637-2063 to 66
                                </Typography>
                                <Typography component="li" variant="body1">
                                    By address: #19 Brixton Street. Brgy. Kapitolyo Pasig City
                                </Typography>

                            </ul>
                        </Box>
                    </Box>
                </Box>

              


            </Box>

            <Footer />
      
        </>
    )
}

export default Privacy;