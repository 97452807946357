import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import SearchIcon from '@mui/icons-material/Search';

import copHeaderDesktop from "../../assets/cop_header_desktop.png";
import copInfoDesktop from "../../assets/cop_info_desktop.png";
import copProcessDesktop from "../../assets/cop_process_desktop.png";

import copHeaderMobile from "../../assets/cop_header_mobile.png";
import copInfoMobile from "../../assets/cop_info_mobile.png";
import copMaskDesktop from "../../assets/cop_maskgroup_desktop.png";
import copProcessMobile from "../../assets/cop_process_mobile.png";

function CashOnPickup() {
    return (
       <>
        <Header />

        {/* for mobile */}
        <Box sx={{display: {xs:'flex', sm:'flex', md:'none', flexDirection:'column'}}}>
        <Link href="/branches"><Box sx={{cursor:'pointer', display: {xs:'flex', sm:'none', md:'none'}}}>
                <img src={copHeaderMobile} style={{width:'100%'}}/>
            </Box></Link>

            <Box sx={{cursor:'pointer', display: {xs:'none', sm:'flex', md:'none'}}}>
                <img src={copHeaderDesktop} style={{width:'100%'}}/>
            </Box>

            <Box sx={{padding:'15px'}}>
                <Box sx={{display: {xs:'flex', sm:'none', md:'none'}}}>
                   <img src={copInfoMobile} style={{width:'100%'}} />
                </Box>
                <Box sx={{display: {xs:'none', sm:'flex', md:'none'}}}>
                   <img src={copInfoDesktop} style={{width:'100%'}} />
                </Box>

                <Box sx={{display: {xs:'flex', sm:'none', md:'none'}}}>
                   <img src={copMaskDesktop} style={{width:'100%'}} />
                </Box>

                <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Box sx={{display:'flex', alignItems:'center', flex:1}}>
                        <Typography variant="h6" gutterBottom sx={{color: '#2E683B', fontSize:'22px', paddingTop:'15px', paddingLeft:'0px'}}>Find a branch near you</Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center', paddingTop:'15px', flex:1}}>
                    <Box sx={{textAlign:'center', marginTop: '15px'}}>
                                <Link href="/branches" underline="none"><Button startIcon={<SearchIcon />} sx={{backgroundColor: '#fff', width:'100%', letterSpacing: '0px', color: '#2E683B', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'14px', textTransform: 'none', padding:'10px', "&:hover": {backgroundColor: '#fff', border:'1px solid #2E683B', padding: '5px'}}}>Find a near branch near you</Button></Link>
                            </Box> 
                    </Box>
                </Box>
            </Box>

            <Box sx={{width:'100%', display: {xs:'flex', sm:'none', md:'none'}}}>
                   <img src={copProcessMobile} style={{width:'100%'}} />
            </Box>

            <Box sx={{width:'100%', display: {xs:'none', sm:'flex', md:'none'}}}>
                   <img src={copProcessDesktop} style={{width:'100%'}} />
            </Box>
        </Box>

        {/* for non mobile */}
        <Box sx={{display:{xs:'none', sm:'none', md:'flex'}, flexDirection:'column'}}>
        <Link href="/branches"><Box sx={{cursor:'pointer'}}>
                <img src={copHeaderDesktop} style={{width:'100%'}}/>
            </Box></Link>

            <Box sx={{padding:'50px'}}>
                <Box sx={{width:'100%', backgroundColor: '#2E683B', borderRadius: '10px', display:'flex'}}>
                   <img src={copInfoDesktop} style={{width:'100%'}} />
                </Box>

                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Box sx={{display:'flex', alignItems:'center', flex:1}}>
                        <Typography variant="h6" gutterBottom sx={{color: '#2E683B', fontSize:'27px', paddingTop:'30px', paddingLeft:'50px'}}>Find a branch near you</Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center', paddingTop:'30px', flex:1}}>
                        <Box sx={{textAlign:'center', marginTop: '15px'}}>
                            <Link href="/branches" underline="none"><Button startIcon={<SearchIcon />} sx={{backgroundColor: '#fff', width:'450px', letterSpacing: '0px', color: '#2E683B', border:'1px solid #2E683B', borderRadius:'25px', fontWeight:'bold', fontSize:'27px', textTransform: 'none', padding:'5px', "&:hover": {backgroundColor: '#fff', border:'1px solid #2E683B', padding: '5px'}}}>Find a near branch near you</Button></Link>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box sx={{width:'100%'}}>
                   <img src={copProcessDesktop} style={{width:'100%'}} />
            </Box>
        </Box>



        <Footer />
       </>
    )
}

export default CashOnPickup;