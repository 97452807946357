
const base_url= "https://jrs-core-api.azurewebsites.net";
const province_base_url = "https://jrs-apiratecalc.azurewebsites.net";
const careers_url= "https://jrs-core-api.azurewebsites.net/api"; 

const ApiService = {
    getBranches: function () {
        let data = {
            method: 'GET'
        };

        return fetch(base_url + "/api/Branch/GetBranchesInAzure", data);
    },
    trackParcel: function (airbill, code) {
        let data = {
            method: 'GET'
        };

        return fetch(base_url + "/api/Tracking/trackairbill?airbill=" + airbill, data);
    },
   
    calculateRate: function(model) {
        let data = {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        return fetch(base_url + "/api/OnlineShipping/RateCalculatorAPI", data);
    },
   
    getScopeTowns: function(name) {
        let data = {
            method: 'GET',
            body: null,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        return fetch(base_url + "/Scope/GetMunicipalsByProvName?name="+ name);
    },
 
    getActiveCareers: function () {
        let data = {
            method: 'GET'
        };

        return fetch(careers_url + "/Careers/GetActiveCareers", data);
    },
    getCareerInfo: function (id) {
        let data = {
            method: 'GET'
        };

        return fetch(careers_url + "/Careers/GetCareer?id=" + id, data);
    },
    uploadFile: function (file) {
        let formData = new FormData();
        formData.append('files', file);

        let data = {
            method: 'POST',
            body: formData,
        };

        return fetch(careers_url + "/Careers/UploadFile", data);
    },
    addApplicant: function (model) {
        let data = {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        return fetch(careers_url + "/Careers/AddApplicant", data);
    },
}

export default ApiService;