import React from "react";
import { useLocation } from 'react-router-dom'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";



function TermsAndConditions() {

    const [readMoreModalOpen, setReadMoreModalOpen] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
    }, [location.pathname]);

    const handleClose = () => {
        setReadMoreModalOpen(false);
    }

    const openReadMoreModal = () => {
        setReadMoreModalOpen(true);
    }

    return (
        <>
            <Header />

            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column' }}>

            <Box sx={{ textAlign: 'center', padding: '30px' }} id="profile">
                    <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>JRS Terms and Conditions</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ width: '90%' }}>

                        <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                                Last updated: [January 2024]
                            </Typography>
                            

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                            Welcome to JRS's website. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                1. Acceptance of Terms:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                By using JRS's website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use the website.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                2. Use of the Website:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                You may use this website for lawful purposes only.
                                </Typography>
                                <Typography component="li" variant="body1">
                                You must not use this website in any way that may cause damage to the website or impair the availability or accessibility of the website.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                3. User Account:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                Some features of the website may require you to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                4. Intellectual Property:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                The content, design, and logos on this website are the intellectual property of JRS. You may not use, reproduce, or distribute any content from this website without prior written permission from JRS.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                5. Third-Party Links:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                This website may contain links to third-party websites. JRS is not responsible for the content or privacy practices of these external sites.
                                </Typography>
                            </ul>


                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                6. Privacy Policy:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS privacy policy outlines how your personal information is collected, used, and disclosed. By using this website, you consent to the practices described in the privacy policy.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                7. Limitation of Liability:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS is not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this website.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                8. Modification of Terms:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS reserves the right to modify these terms and conditions at any time. It is your responsibility to check for updates regularly. Continued use of the website after changes constitutes acceptance of the modified terms.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                9. Termination of Access:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS reserves the right to terminate or restrict access to the website for any reason without notice.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                10. Governing Law:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                These terms and conditions are governed by and construed in accordance with the laws of Philippines, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                11. Contact Information:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                For any questions regarding these terms and conditions, please contact JRS at jrs_dpo@jrs-express.com.ph.
                                </Typography>
                            </ul>
                        </Box>
                    </Box>
                </Box>

              


            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, flexDirection: 'column' }}>

                <Box sx={{ textAlign: 'center', padding: '30px' }} id="profile">
                    <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>JRS Terms and Conditions</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ width: '90%' }}>

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                                Last updated: [January 2024]
                            </Typography>
                            

                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '5px' }}>
                            Welcome to JRS's website. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                1. Acceptance of Terms:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                By using JRS's website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use the website.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                2. Use of the Website:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                You may use this website for lawful purposes only.
                                </Typography>
                                <Typography component="li" variant="body1">
                                You must not use this website in any way that may cause damage to the website or impair the availability or accessibility of the website.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                3. User Account:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                Some features of the website may require you to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                4. Intellectual Property:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                The content, design, and logos on this website are the intellectual property of JRS. You may not use, reproduce, or distribute any content from this website without prior written permission from JRS.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                5. Third-Party Links:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                This website may contain links to third-party websites. JRS is not responsible for the content or privacy practices of these external sites.
                                </Typography>
                            </ul>


                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                6. Privacy Policy:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS privacy policy outlines how your personal information is collected, used, and disclosed. By using this website, you consent to the practices described in the privacy policy.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                7. Limitation of Liability:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS is not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this website.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                8. Modification of Terms:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS reserves the right to modify these terms and conditions at any time. It is your responsibility to check for updates regularly. Continued use of the website after changes constitutes acceptance of the modified terms.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                9. Termination of Access:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                JRS reserves the right to terminate or restrict access to the website for any reason without notice.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                10. Governing Law:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                These terms and conditions are governed by and construed in accordance with the laws of Philippines, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
                                </Typography>
                            </ul>

                            <Typography variant="h6" gutterBottom sx={{ color: '##000', fontWeight: 'bold', marginBottom: '5px' }}>
                                11. Contact Information:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                For any questions regarding these terms and conditions, please contact JRS at jrs_dpo@jrs-express.com.ph.
                                </Typography>
                            </ul>
                            
                        </Box>
                    </Box>
                </Box>

              


            </Box>

            <Footer />
      
        </>
    )
}

export default TermsAndConditions;