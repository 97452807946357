import React from "react";
import { useLocation } from 'react-router-dom'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import aboutDesktop from "../../assets/about_desktop.png";
import aboutOurStory from "../../assets/about_ourstory_desktop.png";
import aboutMvDesktop from "../../assets/about_mv_desktop.png";

import aboutMobile from "../../assets/about_image_mobile.png";
import aboutHistoryMobile from "../../assets/about_history_mobile.png";
import aboutVisionMobile from "../../assets/about_vision_mobile.png";
import aboutMissionMobile from "../../assets/about_mission_mobile.png";

import throughTheYears from "../../assets/through_the_years.png";
import historyOne from "../../assets/history_one.png";
import historyTwo from "../../assets/history_two.png";
import historyThree from "../../assets/history_three.png";
import historyFour from "../../assets/history_four.png";
import historyFive from "../../assets/history_five.png";
import { Icon } from "@mui/material";

function About() {
    
    const [readMoreModalOpen, setReadMoreModalOpen] = React.useState(false);
    const location = useLocation();  

    React.useEffect(() => {
    }, [location.pathname]);

    const handleClose = () => {
        setReadMoreModalOpen(false);
    }

    const openReadMoreModal = () => {
        setReadMoreModalOpen(true);
    }

    return (
        <>
            <Header />

            <Box sx={{display: {xs: 'flex', sm:'flex', md:'none'}, flexDirection: 'column'}}>

                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center', backgroundImage: `url(${aboutMobile})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', height:'200px'}}>
                    <Typography variant="h5" sx={{color: '#fff', fontWeight: 'bold'}}>About Us</Typography>
                    <Divider sx={{border: '1px solid #fff', width:'100px', marginTop: '10px'}}/>
                </Box>

                <Box sx={{display:'flex', flexDirection: 'column', padding:'30px'}} id="profile">
                    <Typography variant="h5" gutterBottom sx={{color: '#000', fontWeight: 'bold'}}>Profile</Typography>
                    <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight:'bold', marginBottom:'5px'}}>
                                JRS Express is a leading express delivery company in the Philippines.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px'}}>
                            For more than 60 years, JRS has been the leading courier company for next-day delivery services in the Philippines. Any time and everywhere, trust that JRS Express will deliver.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            We are here to serve you, even through challenging situations, and the Holidays.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            With over 450 branches nationwide and overseas, our primary purpose is to connect people and products with utmost efficiency. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            In 1960, we marked the beginning of our journey when JRS Business Corporation was acquired and registered by Dr. Jaime L. Claparols. Over the next decades, we steadily expanded our reach and established a vast network of over 450 branches nationwide and overseas.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            2009 marked the beginning of our online journey, allowing us to connect with our customers in new and exciting ways. We took a digital leap, improving our webpage and establishing our presence on Facebook. By 2014, we had completely embraced digitalization, with our website becoming a hub for tracking packages, discovering our services, and exploring the scope of our delivery options. To ensure that you continue to receive support, we have assembled a dedicated team ready to respond to your inquiries—24/7/365.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            In 2020, we took a significant step toward digital transformation by launching our new website, making it easier to access our services online. As we moved towards cashless transactions, we partnered with GCash, ShopeePay, and Alipay, offering our customers more payment options and convenience. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            Moving into 2023, JRS Express remains committed to innovation and excellence. As an affirmation of our trustworthiness and reliability, we are successfully accredited as a <b>national courier for the Supreme Court of the Philippines - Office of the Court Administrator.</b>
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            JRS Express recognizes the importance of offering flexibility to our customers, which is why we've launched a Cash on Delivery service, ensuring that your payment preferences are always catered to.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            We deliver to 17 regions, 82 provinces, covering 35,000 barangays and 90% of the reachable areas in the Philippines. We cater to a wide range of services, including express cargo (time-sensitive), general cargo, freight, intra-city on-demand delivery, lipat-bahay delivery, and international business. As a 100% Filipino-owned business, we take immense pride in serving our nation.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            Our story is a testament to our enduring commitment to service. We are here to serve you, even through challenging situations and the holidays. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', fontStyle: "italic", fontWeight: 'bold'}}>
                            Kahit kailan maasahan, kahit saan makakarating.
                            </Typography>
                </Box>

                <Box sx={{display:'flex', justifyContent: 'center', marginBottom:'0px'}}>
                    <Box sx={{flex: 1, display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
                        <Box sx={{width:'90%', display:'flex', flexDirection:'column'}}>
                            <Box sx={{marginBottom:'30px'}}>
                                <img src={aboutOurStory} style={{width:'100%'}}/>
                            </Box>
                        
                            <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight:'bold', marginBottom:'5px'}}>
                                Our Story
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px', marginBottom: '30px'}}>
                            At JRS Express, our vision drives us to become a leading force in logistics, offering world-class services while actively contributing to our nation's growth. With over 450 branches nationwide and overseas, our primary purpose is to connect people and products with greatest efficiency. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px', marginBottom: '30px'}}>
                            Throughout the years, we've embraced digitalization, providing services such as online tracking, 24/7 customer support, and multiple payment options, including Cash on Pick up, Payment on Delivery, and Cash on Delivery. Our accreditation as a national courier for the Supreme Court of the Philippines - Office of the Court Administrator stands as a testament to our unwavering dedication to excellence and reliability. We are here to serve you, even through challenging situations and the holidays.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', flexGrow: 0, cursor: 'pointer'}} onClick={openReadMoreModal}>
                                Read more >
                            </Typography>
                        </Box>
                       
                    </Box>
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center', backgroundImage: `url(${aboutHistoryMobile})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height:'500px'}} id="history">
                    <Typography variant="h5" sx={{color: '#fff', fontWeight: 'bold'}}>History</Typography>
                    <Divider sx={{border: '1px solid #fff', width:'100px', marginTop: '10px'}}/>
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'column', padding:'30px'}} id="vision">
                    <Box sx={{marginBottom: '15px', textAlign:'center'}}>
                        <img src={aboutVisionMobile} style={{width:'100%'}}/>
                    </Box>
                    <Typography variant="h5" gutterBottom sx={{color: '#000', fontWeight: 'bold'}}>Vision</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        To be among the leading courier and logistics companies of the world.
                    </Typography>
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'column', padding:'30px', paddingBottom: '60px'}} id="mission">
                    <Box sx={{marginBottom: '15px', textAlign:'center'}}>
                        <img src={aboutMissionMobile}  style={{width:'100%'}}/>
                    </Box>
                    <Typography variant="h5" gutterBottom sx={{color: '#000', fontWeight: 'bold'}}>Mission</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                       To provide world-class courier services, logistics and supply-chain solutions to all and to foster nation-building.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{display: {xs: 'none', sm:'none', md:'flex'}, flexDirection: 'column'}}>

                <Box sx={{textAlign:'center', padding:'30px'}} id="profile">
                     <Typography variant="h5" sx={{color: '#000', fontWeight: 'bold'}}>About Us</Typography>
                </Box>

                <Box sx={{display:'flex', justifyContent: 'center', marginBottom:'30px'}}>
                    <Box sx={{flex: 1, display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
                        <Box sx={{width:'90%'}}>
                            <Box sx={{marginBottom:'30px'}}>
                                <img src={aboutDesktop} style={{width:'100%'}}/>
                            </Box>
                            <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight:'bold', marginBottom:'5px'}}>
                                JRS Express is a leading express delivery company in the Philippines.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px'}}>
                            For more than 60 years, JRS has been the leading courier company for next-day delivery services in the Philippines. Any time and everywhere, trust that JRS Express will deliver.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            We are here to serve you, even through challenging situations, and the Holidays.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            With over 450 branches nationwide and overseas, our primary purpose is to connect people and products with utmost efficiency. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            In 1960, we marked the beginning of our journey when JRS Business Corporation was acquired and registered by Dr. Jaime L. Claparols. Over the next decades, we steadily expanded our reach and established a vast network of over 450 branches nationwide and overseas.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            2009 marked the beginning of our online journey, allowing us to connect with our customers in new and exciting ways. We took a digital leap, improving our webpage and establishing our presence on Facebook. By 2014, we had completely embraced digitalization, with our website becoming a hub for tracking packages, discovering our services, and exploring the scope of our delivery options. To ensure that you continue to receive support, we have assembled a dedicated team ready to respond to your inquiries—24/7/365.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            In 2020, we took a significant step toward digital transformation by launching our new website, making it easier to access our services online. As we moved towards cashless transactions, we partnered with GCash, ShopeePay, and Alipay, offering our customers more payment options and convenience. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            Moving into 2023, JRS Express remains committed to innovation and excellence. As an affirmation of our trustworthiness and reliability, we are successfully accredited as a <b>national courier for the Supreme Court of the Philippines - Office of the Court Administrator.</b>
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            JRS Express recognizes the importance of offering flexibility to our customers, which is why we've launched a Cash on Delivery service, ensuring that your payment preferences are always catered to.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            We deliver to 17 regions, 82 provinces, covering 35,000 barangays and 90% of the reachable areas in the Philippines. We cater to a wide range of services, including express cargo (time-sensitive), general cargo, freight, intra-city on-demand delivery, lipat-bahay delivery, and international business. As a 100% Filipino-owned business, we take immense pride in serving our nation.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal'}}>
                            Our story is a testament to our enduring commitment to service. We are here to serve you, even through challenging situations and the holidays. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', fontStyle: "italic", fontWeight: 'bold'}}>
                            Kahit kailan maasahan, kahit saan makakarating.
                            </Typography>
                        </Box>
                       
                    </Box>
                </Box>

                <Box sx={{display:'flex', justifyContent: 'center', marginBottom:'0px'}}>
                    <Box sx={{flex: 1, display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
                        <Box sx={{width:'90%', display:'flex', flexDirection:'column'}}>
                            <Box sx={{marginBottom:'30px'}}>
                                <img src={aboutOurStory} style={{width:'100%'}}/>
                            </Box>
                        
                            <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight:'bold', marginBottom:'5px'}}>
                                Our Story
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px', marginBottom: '30px'}}>
                            At JRS Express, our vision drives us to become a leading force in logistics, offering world-class services while actively contributing to our nation's growth. With over 450 branches nationwide and overseas, our primary purpose is to connect people and products with greatest efficiency. 
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px', marginBottom: '30px'}}>
                            Throughout the years, we've embraced digitalization, providing services such as online tracking, 24/7 customer support, and multiple payment options, including Cash on Pick up, Payment on Delivery, and Cash on Delivery. Our accreditation as a national courier for the Supreme Court of the Philippines - Office of the Court Administrator stands as a testament to our unwavering dedication to excellence and reliability. We are here to serve you, even through challenging situations and the holidays.
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', flexGrow: 0, cursor: 'pointer'}} onClick={openReadMoreModal}>
                                Read more >
                            </Typography>

                        
                        </Box>
                       
                    </Box>
                </Box>

                <Box sx={{display: 'flex', backgroundColor: '#f4f4f4', height:'400px', padding:'45px'}} id="history">

                    <Box sx={{flex: 1, backgroundImage: `url(${aboutMvDesktop})`, backgroundSize:'100% 100%'}}>

                    </Box>
                    <Box sx={{flex: 1, backgroundColor: '#fff', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                          <Box sx={{width: '75%'}}>
                            <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight:'bold', marginBottom:'5px'}} id="vision">
                                Vision
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'30px'}}>
                                To be among the leading courier and logistics companies of the world.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight:'bold', marginBottom:'5px'}} id="mission">
                                Mission
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight:'normal', marginBottom:'5px'}}>
                                To provide world-class courier services, logistics and supply-chain solutions to all and to foster nation-building.
                            </Typography>
                          </Box>
                    </Box>
                </Box>

            </Box>

            <Footer />

            <Modal
                 open={readMoreModalOpen}
                 onClose={handleClose}
                 sx={{margin:'15px', overflowY: 'auto', backgroundColor: '#fff'}}>

                <Box sx={{backgroundColor: '#fff'}}>
                    <Box sx={{position:'relative'}}>
                        <img src={throughTheYears} style={{width:'100%'}}/>     
                        <Box sx={{zIndex: 1000, position:'absolute', top:'0px', right:'15px'}}>
                            <IconButton sx={{color: '#fff', cursor: 'pointer'}} onClick={handleClose}>
                                <CloseIcon sx={{width:'40px', height: '40px' }}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{}}>
                        <img src={historyOne} style={{width:'100%'}}/>
                    </Box>
                    <Box sx={{}}>
                        <img src={historyTwo} style={{width:'100%'}}/>
                    </Box>
                    <Box sx={{}}>
                        <img src={historyThree} style={{width:'100%'}}/>
                    </Box>
                    <Box sx={{}}>
                        <img src={historyFour} style={{width:'100%'}}/>
                    </Box>
                    <Box sx={{}}>
                        <img src={historyFive} style={{width:'100%'}}/>
                    </Box>
                </Box>  

            </Modal>
        </>
    )
}

export default About;