import { Routes, Route } from "react-router-dom";
import Main from "../views/main/main";
import About from "../views/about/about";
import Privacy from "../views/privacy/privacy";
import DataPrivacy from "../views/dataPrivacy/dataPrivacy";
import TermsAndConditions from "../views/termsAndConditions/termsAndConditions";
import Rates from "../views/rates/rates";
import Services from "../views/services/services";
import CashOnDelivery from "../views/cashOnDelivery/cashOnDelivery";
import CashOnPickup from "../views/cashOnPickup/cashOnPickup";
import PaymentOnDelivery from "../views/paymentOnDelivery/paymentOnDelivery";
import BranchLocator from "../views/branchLocator/branchLocator";
import TrackTrace from "../views/trackTrace/trackTrace";
import Scope from "../views/scope/scope";
import SendCourtDocuments from "../views/sendCourtDocuments/sendCourtDocuments";
import JrsInternational from "../views/jrsInternational/jrsInternational";
import JrsCareers from "../views/jrsCareers/jrsCareers";
import JrsCareerDetails from "../views/jrsCareerDetails/jrsCareerDetails";

function RoutesMain() {
    return (
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/termsAndConditions" element={<TermsAndConditions/>}/>
            <Route path="/rates" element={<Rates/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/cashOnDelivery" element={<CashOnDelivery/>}/>
            <Route path="/cashOnPickup" element={<CashOnPickup/>}/>
            <Route path="/paymentOnDelivery" element={<PaymentOnDelivery/>}/>
            <Route path="/branches" element={<BranchLocator/>}/>
            <Route path="/track" element={<TrackTrace/>}/>
            <Route path="/scope" element={<Scope/>}/>
            <Route path="/sendCourtDocuments" element={<SendCourtDocuments/>}/>
            <Route path="/international" element={<JrsInternational/>}/>
            <Route path="/careers" element={<JrsCareers/>}/>
            <Route path="/careers/:id" element={<JrsCareerDetails/>}/>
            <Route path="/dataPrivacy" element={<DataPrivacy/>}/>
        </Routes>
    )
}

export default RoutesMain;