import React from "react";
import {useParams} from 'react-router-dom';
import parse from 'html-react-parser';
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";


import FileCopyIcon from '@mui/icons-material/FileCopy';
import ApiService from "../../services/apiService";

function JrsCareerDetails() {
    const params = useParams();

    const [careerInfo, setCareerInfo] = React.useState(null);
    const [applyModalOpen, setApplyModalOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [profile, setProfile] = React.useState("");
    const [file, setFile] = React.useState(null);

    const handleClose = () => {
        setApplyModalOpen(false);
    }

    const applyJob = () => {
        setApplyModalOpen(true);
    }

    const uploadCv = () => {
        document.getElementById("upload-cv").click();
    }

    const onFileChange = (ev) => {
        console.log("event",  ev);

        setFile(ev.target.files[0]);
    }

    const removeUploaded = () => {
        setFile(null);
        document.getElementById("upload-cv").value = "";
    }

    const apply = () => {
        ApiService.uploadFile(file)
            .then(response => response.json())
            .then(json => {
                let data = json;

                console.log("uploaded", data);

                let model = {
                    Id: careerInfo.id,
                    Name: name,
                    ContactNumber: number,
                    Email: email,
                    ProfileLink: profile,
                    FileId: data[0].id,
                    FileName: data[0].fileName
                };

                ApiService.addApplicant(model)
                    .then(response => response.json())
                    .then(json => {
                        setName("");
                        setEmail("");
                        setNumber("");
                        setProfile("");
                        setFile(null);
                        document.getElementById("upload-cv").value = "";
                        setApplyModalOpen(false);
                    })
            });
    }

    React.useEffect(() => {
        ApiService.getCareerInfo(params.id)
            .then(response => response.json())
            .then(json => {

                json.description = json.description.replace("/n", "<br/>");
                setCareerInfo(json);
            })

        // setCareerInfo({
        //         "id": "47db21c1-03fc-48db-9db8-f1e0f46b8129",
        //         "jobPosition": "Sales Executive 4",
        //         "category": "Sales",
        //         "description": "We are looking for a Sales Clerk to join our team and help customers find the items they are looking for along with answering any questions they have during their time shopping in our store. <br/><br/>Sales Clerk responsibilities include answering customer questions and helping customers finalize their purchases with us. <br/><br/>Ultimately, you will work directly with customers to help them locate items and assist them with their purchases.",
        //         "linkedInLink": "https://linkedin.com",
        //         "isActive": true,
        //         "responsibilities": [
        //             "REsp1"
        //         ],
        //         "requirements": [
        //             "Req 1"
        //         ],
        //         "benefits": [
        //             "Ben 1"
        //         ],
        //         "createdAt": "0001-01-01T00:00:00"
        //     });
    }, [])
    return (
        <>
            <Header />

            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'30px'}}>
                 <Typography variant="h6" gutterBottom><b>Job Details</b></Typography>
            </Box>

            {careerInfo !== null ? (
                <>
                    <Box sx={{width:'80%', margin:'0 auto'}}>
                        <Box sx={{marginBottom:'10px'}}>
                            <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>Job Position</Typography>
                        </Box>

                        <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                            {careerInfo?.jobPosition}
                        </Box>

                        <Box sx={{marginBottom:'10px'}}>
                            <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>Category</Typography>
                        </Box>

                        <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                            {careerInfo?.category}
                        </Box>

                        <Box sx={{marginBottom:'10px'}}>
                            <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>LinkedIn Link</Typography>
                        </Box>

                        <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                            <a href={careerInfo.linkedInLink} target="_blank">{careerInfo.linkedInLink}</a>
                        </Box>

                        <Box sx={{marginBottom:'10px'}}>
                            <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>About The Job</Typography>
                        </Box>

                        <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                            {parse(careerInfo?.description)}
                        </Box>

                        {careerInfo.responsibilities.length > 0 ? (
                            <>
                                <Box sx={{marginBottom:'10px'}}>
                                    <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>What You're Going To Do</Typography>
                                </Box>

                                <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                                    <ul>
                                        {careerInfo.responsibilities.map((row) => (
                                            <li>{row}</li>
                                        ))}
                                    </ul>
                                </Box>
                            </>
                        ) : ""}

                        {careerInfo.requirements.length > 0 ? (
                            <>
                                <Box sx={{marginBottom:'10px'}}>
                                    <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>Why You're Fit For This Role</Typography>
                                </Box>

                                <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                                    <ul>
                                        {careerInfo.requirements.map((row) => (
                                            <li>{row}</li>
                                        ))}
                                    </ul>
                                </Box>
                            </>
                        ) : ""}

                        {careerInfo.benefits.length > 0 ? (
                            <>
                                <Box sx={{marginBottom:'10px'}}>
                                    <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>Our Benefits</Typography>
                                </Box>

                                <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                                    <ul>
                                        {careerInfo.benefits.map((row) => (
                                            <li>{row}</li>
                                        ))}
                                    </ul>
                                </Box>
                            </>
                        ) : ""}

                        <Box sx={{marginBottom:'10px'}}>
                            <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>How to be Part of Our Team</Typography>
                        </Box>

                        <Box sx={{marginBottom:'30px', fontSize:'20px', fontWeight:'500'}}>
                            Interested in this position? Click the Apply button below and send us your CV and details. We will contact you to update you about your application process.
                        </Box>

                        <Box sx={{textAlign:'center'}}>
                            <Button
                            sx={{
                                backgroundColor: "#046A38",
                                color: "#fff",
                                height: "50px",
                                padding:'30px',
                                marginBottom:'30px',
                                fontWeight: "bold",
                                "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                            }}
                            onClick={applyJob}
                            >
                            APPLY FOR THIS ROLE
                            </Button>
                        </Box>
                        
                    </Box>
                </>
            ) : ""}
          
            <Footer/>

            <Modal
                open={applyModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Paper sx={{maxWidth:'600px', margin:'0 auto', marginTop: '15px', padding:'15px'}}>
                    <Box sx={{textAlign:'right'}}>
                        <IconButton sx={{color: '#000', cursor: 'pointer'}} onClick={handleClose}>
                            <CloseIcon sx={{width:'20px', height: '20px' }}/>
                        </IconButton>
                    </Box>

                    <Box sx={{marginBottom:'30px', textAlign:'center'}}>
                        <Typography variant="h5" gutterBottom sx={{fontSize:'24px', color: '#046A38', fontWeight:'bold'}}>Apply For This Role</Typography>
                    </Box>

                    <Box sx={{padding: '15px'}}>
                        <Box sx={{marginBottom:'15px', fontWeight:'bold'}}>Name</Box>
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            value={name}
                            sx={{ width: "100%", marginBottom:'15px' }}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />

                        <Box sx={{marginBottom:'15px', fontWeight:'bold'}}>Email</Box>
                        <TextField
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            value={email}
                            sx={{ width: "100%", marginBottom: '15px' }}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />

                        <Box sx={{marginBottom:'15px', fontWeight:'bold'}}>Contact Number</Box>
                        <TextField
                            id="outlined-basic"
                            label="Contact Number"
                            variant="outlined"
                            value={number}
                            sx={{ width: "100%", marginBottom:'15px' }}
                            onChange={(e) => {
                                setNumber(e.target.value);
                            }}
                        />

                        <Box sx={{marginBottom:'15px', fontWeight:'bold'}}>Profile Link</Box>
                        <TextField
                            id="outlined-basic"
                            label="Profile Link"
                            variant="outlined"
                            value={profile}
                            sx={{ width: "100%", marginBottom:'15px' }}
                            onChange={(e) => {
                                setProfile(e.target.value);
                            }}
                        />

                        <Box sx={{marginBottom:'15px', fontWeight:'bold'}}>Upload CV</Box>
                        <input type="file" style={{display:'none'}} id="upload-cv" onChange={(e) => onFileChange(e)}/>
                        
                        {file !== null ? (
                            <Box sx={{display:'flex'}}>
                                <Box>
                                    <FileCopyIcon />
                                </Box>
                                <Box>
                                    {file.name}
                                </Box>
                                <Box sx={{marginLeft:'15px'}}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#046A38",
                                            color: "#fff",
                                            height: "20px",
                                            padding:'15px',
                                            fontWeight: "bold",
                                            "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                                        }}
                                        onClick={removeUploaded}
                                        >
                                        REMOVE 
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                        <Button
                            sx={{
                                backgroundColor: "#046A38",
                                color: "#fff",
                                height: "20px",
                                padding:'15px',
                                fontWeight: "bold",
                                "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                            }}
                            onClick={uploadCv}
                            >
                            UPLOAD 
                        </Button>
                        )}
                        


                        <Box sx={{textAlign:'center', marginTop:'30px'}}>
                            <Button
                            sx={{
                                backgroundColor: "#046A38",
                                color: "#fff",
                                height: "40px",
                                padding:'30px',
                                fontWeight: "bold",
                                "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                            }}
                            onClick={apply}
                            >
                            APPLY 
                            </Button>
                        </Box>
                    </Box>
                   
                </Paper>
            </Modal>

           
        </>
    )
}

export default JrsCareerDetails;