import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import ApiService from "../../services/apiService";

function Scope() {
    const [provinces, setProvinces] = React.useState([
        { id: 1, name: "Abra", regionID: 1 },
        { id: 2, name: "Agusan del Norte", regionID: 16 },
        { id: 3, name: "Agusan del Sur", regionID: 16 },
        { id: 4, name: "Aklan", regionID: 9 },
        { id: 5, name: "Albay", regionID: 8 },
        { id: 6, name: "Antique", regionID: 9 },
        { id: 7, name: "Apayao", regionID: 1 },
        { id: 8, name: "Aurora", regionID: 5 },
        { id: 9, name: "Basilan", regionID: 17 },
        { id: 10, name: "Bataan", regionID: 5 },
        { id: 11, name: "Batanes", regionID: 4 },
        { id: 12, name: "Batangas", regionID: 6 },
        { id: 13, name: "Benguet", regionID: 1 },
        { id: 14, name: "Biliran", regionID: 11 },
        { id: 15, name: "Bohol", regionID: 10 },
        { id: 16, name: "Bukidnon", regionID: 13 },
        { id: 17, name: "Bulacan", regionID: 5 },
        { id: 18, name: "Cagayan", regionID: 4 },
        { id: 19, name: "Camarines Norte", regionID: 8 },
        { id: 20, name: "Camarines Sur", regionID: 8 },
        { id: 21, name: "Camiguin", regionID: 13 },
        { id: 22, name: "Capiz", regionID: 9 },
        { id: 23, name: "Catanduanes", regionID: 8 },
        { id: 24, name: "Cavite", regionID: 6 },
        { id: 25, name: "Cebu", regionID: 10 },
        { id: 26, name: "Compostela Valley", regionID: 14 },
        { id: 27, name: "Davao del Norte", regionID: 14 },
        { id: 28, name: "Davao del Sur", regionID: 14 },
        { id: 29, name: "Davao Oriental", regionID: 14 },
        { id: 30, name: "Dinagat Islands", regionID: 11 },
        { id: 31, name: "Eastern Samar", regionID: 11 },
        { id: 32, name: "Guimaras", regionID: 9 },
        { id: 33, name: "Ifugao", regionID: 1 },
        { id: 34, name: "Ilocos Norte", regionID: 3 },
        { id: 35, name: "Ilocos Sur", regionID: 3 },
        { id: 36, name: "Iloilo", regionID: 9 },
        { id: 37, name: "Isabela", regionID: 4 },
        { id: 38, name: "Kalinga", regionID: 1 },
        { id: 39, name: "La Union", regionID: 3 },
        { id: 40, name: "Laguna", regionID: 6 },
        { id: 41, name: "Lanao del Norte", regionID: 13 },
        { id: 42, name: "Lanao del Sur", regionID: 17 },
        { id: 43, name: "Leyte", regionID: 11 },
        { id: 44, name: "Maguindanao", regionID: 17 },
        { id: 45, name: "Marinduque", regionID: 7 },
        { id: 46, name: "Masbate", regionID: 8 },
        { id: 47, name: "Metro Manila", regionID: 2 },
        { id: 48, name: "Misamis Occidental", regionID: 13 },
        { id: 49, name: "Misamis Oriental", regionID: 13 },
        { id: 50, name: "Mountain Province", regionID: 1 },
        { id: 51, name: "Negros Occidental", regionID: 9 },
        { id: 52, name: "Negros Oriental", regionID: 10 },
        { id: 53, name: "North Cotabato", regionID: 15 },
        { id: 54, name: "Northern Samar", regionID: 11 },
        { id: 55, name: "Nueva Ecija", regionID: 5 },
        { id: 56, name: "Nueva Vizcaya", regionID: 4 },
        { id: 57, name: "Occidental Mindoro", regionID: 7 },
        { id: 58, name: "Oriental Mindoro", regionID: 7 },
        { id: 59, name: "Palawan", regionID: 7 },
        { id: 60, name: "Pampanga", regionID: 5 },
        { id: 61, name: "Pangasinan", regionID: 3 },
        { id: 62, name: "Quezon", regionID: 6 },
        { id: 63, name: "Quirino", regionID: 4 },
        { id: 64, name: "Rizal", regionID: 6 },
        { id: 65, name: "Romblon", regionID: 7 },
        { id: 66, name: "Samar", regionID: 11 },
        { id: 67, name: "Sarangani", regionID: 15 },
        { id: 68, name: "Shariff Kabunsuan", regionID: 17 },
        { id: 69, name: "Siquijor", regionID: 10 },
        { id: 70, name: "Sorsogon", regionID: 8 },
        { id: 71, name: "South Cotabato", regionID: 15 },
        { id: 72, name: "Southern Leyte", regionID: 11 },
        { id: 73, name: "Sultan Kudarat", regionID: 15 },
        { id: 74, name: "Sulu", regionID: 17 },
        { id: 75, name: "Surigao del Norte", regionID: 16 },
        { id: 76, name: "Surigao del Sur", regionID: 16 },
        { id: 77, name: "Tarlac", regionID: 5 },
        { id: 78, name: "Tawi-Tawi", regionID: 17 },
        { id: 79, name: "Zambales", regionID: 5 },
        { id: 80, name: "Zamboanga del Norte", regionID: 12 },
        { id: 81, name: "Zamboanga del Sur", regionID: 12 },
        { id: 82, name: "Zamboanga Sibugay", regionID: 12 }
    ]);


    const [provinceSelected, setProvinceSelected] = React.useState(-1);
    const [towns, setTowns] = React.useState([]);
    const [townSelected, setTownSelected] = React.useState(-1);

    const [results, setResults] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);


    const changeProvince = (prov) => {
        setProvinceSelected(prov);
        ApiService.getScopeTowns(prov)
            .then(response => response.json())
            .then(json => {
                let data = json;
                let townsList = [];
                let districtsList = [];
                data.forEach(town => {
                    let townObj = {
                        id: town.id,
                        name: town.name,
                        districts: town.districts
                    };
                    townsList.push(townObj);
                    if (town.districts && Array.isArray(town.districts)) {
                        town.districts.forEach(district => {
                            districtsList.push(district);
                        });
                    }
                });
                setTowns(townsList);

            })
    }

    const changeTown = (selectedTownName) => {
        setTownSelected(selectedTownName);
        setIsLoading(true);
        const selectedTownObj = towns.find(town => town.name === selectedTownName);
        if (selectedTownObj) {
            const townDistricts = selectedTownObj.districts.map(district => ({
                coverage: district.coverage,
                coverageNotes: district.coverageNotes,
                name: district.name,
                additionalChargesPerGrams: district.additionalChargesPerGrams,
                additionalChargeForDistance: district.additionalChargeForDistance
            }));
            setResults(townDistricts);
        } else {
            setResults([]);
        }
        setIsLoading(false);
    }

    return (
        <>
            <Header />

            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
                <Typography variant="h6" gutterBottom><b>Scope of Delivery</b></Typography>
                <Divider sx={{ width: '50px', border: '1px solid #046A38', marginBottom: '0px' }} />
            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
                <Typography variant="h6" gutterBottom><b>Scope of Delivery</b></Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ flex: 1, marginLeft: '30px', marginRight: '15px' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Province</Typography>
                    </Box>
                    <Box sx={{ flex: 2 }}>
                        <FormControl fullWidth sx={{ marginTop: '15px', marginBottom: '30px' }}>
                            <InputLabel id="province-label">Province</InputLabel>
                            <Select
                                labelId="province"
                                id="to"
                                label="Province"
                                value={provinceSelected}
                                onChange={(e) => changeProvince(e.target.value)}
                            >
                                {provinces.map(x => (
                                    <MenuItem value={x.name}>{x.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, marginRight: '30px', marginLeft: '15px' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Town</Typography>
                    </Box>
                    <Box sx={{ flex: 2 }}>
                        <FormControl fullWidth sx={{ marginTop: '15px', marginBottom: '30px' }}>
                            <InputLabel id="town-label">Town</InputLabel>
                            <Select
                                labelId="town"
                                id="to"
                                label="Town"
                                value={townSelected}
                                onChange={(e) => changeTown(e.target.value)}
                            >
                                {towns.map(x => (
                                    <MenuItem value={x.name}>{x.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>

            {isLoading ? (
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ color: '#046A38' }} />
                </Box>
            ) : (
                <>
                    {results ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px' }}>
                            <Typography variant="h6" gutterBottom><b>Covered Barangays</b></Typography>
                            <TableContainer component={'div'} sx={{ width: '100%', marginBottom: '30px' }}>
                                <Table aria-label="custom pagination table" size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', borderTop: '1px solid #000', borderLeft: '1px solid #000', backgroundColor: '#046A38', color:'white' }}><b>Barangay</b></TableCell>
                                            <TableCell component="th" scope="row" sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', borderTop: '1px solid #000', borderLeft: '1px solid #000', backgroundColor: '#046A38', color:'white' }}><b>Scope</b></TableCell>
                                            <TableCell component="th" scope="row" sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', borderTop: '1px solid #000', borderLeft: '1px solid #000', backgroundColor: '#046A38', color:'white' }}><b>Remarks</b></TableCell>
                                        </TableRow>
                                        {results.map((districts, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ border: '1px solid #000' }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000'  }}>

                                                    {districts.name }

                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>

                                                    {districts.coverage}

                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>

                                                    {districts.coverageNotes}

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Typography variant="h6" gutterBottom>No districts found</Typography>
                    )}
                </>
            )}





            <Footer />
        </>
    )
}

export default Scope;