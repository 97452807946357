import React from "react";
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Popper from '@mui/material/Popper';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import jrsLogoMobile from '../../assets/logo_mobile.png';
import header from "../../assets/header.png";
import Drawer from "../drawer/drawer";

function Header() {

    const [currentLocation, setCurrentLocation] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const location = useLocation();
    const [subMenuOpen, setSubMenuOpen] = React.useState(false);

    React.useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location.pathname])

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

   


    var headerOptions = [
        {
            name: "Home",
            route: "/"
        },
        {
            name: "Rates",
            route: "/rates"
        },
        {
            name: "Branch Locator",
            route: "/branches"
        },
        {
            name: "Scope",
            route: "/scope"
        },
        {
            name: "Careers @JRS",
            route: "/careers"
        }
    ];

    var headerRightOptions = [
        {
            name: "About Us",
            route: "/about"
        },
        {
            name: "Contact Us",
            route: "#contact"
        }
    ];

    const toggleDrawer = () => {
        setDrawerOpen(true);
    }

    const closeDrawerCallback = (isContact) => {
        setDrawerOpen(false);
        if (isContact) {
            window.scrollTo(0, document.body.scrollHeight);
        }
            
    }

    const scrollDownToContact = (isContact) => {
        if (isContact === "Contact Us") {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    const handleEcommerceClick = () => {
        setSubMenuOpen(!subMenuOpen);
      };


    return (
        <>
            <AppBar position={"static"} sx={{backgroundColor: '#fff', display: {xs:'block', sm:'block', md:'none'}}}>
                <Container>
                    <Drawer isOpen={drawerOpen} drawerCloseCallback={closeDrawerCallback}/>
                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 1}}>
                            <img src={jrsLogoMobile} style={{width:'112px'}}/>
                        </Box>   
                        <Box sx={{flexGrow: 0}}>
                            <IconButton sx={{p:0}} onClick={toggleDrawer}>
                                <MenuIcon sx={{color: '#000'}}/>
                            </IconButton>
                        </Box>               
                    </Toolbar>
                </Container>
            </AppBar>

            <AppBar position={"static"} sx={{backgroundImage: `url(${header})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', height:'80px', display: {xs:'none', sm:'none', md:'block'}}}>               
            </AppBar>
            <AppBar position={"static"} sx={{backgroundColor: '#fff', height:'60px', display: {xs:'none', sm:'none', md:'block'}}}>    
                <Container sx={{maxWidth: '100% !important'}}>
                    <Toolbar>
                        <Box sx={{display:'flex', flexGrow:1, marginLeft: '0px'}}>
                            {headerOptions.map((opt) => (
                                <Box sx={{marginRight: '30px', height:'52px', display:'flex', alignItems:'center', borderBottom: currentLocation === opt.route ? '5px solid #046A38' : ''}}>
                                    <Link underline="none" href={opt.route}><Typography sx={{color:'#046A38'}}><b>{opt.name}</b></Typography></Link>
                                </Box>
                            ))}

                            <Box sx={{marginRight: '30px', height:'52px', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={handleClick}>
                                <Typography sx={{color:'#046A38'}}><b>Services</b></Typography>
                                <ArrowDropDownIcon sx={{color: '#046A38'}}/>
                            </Box>
                            <Popper id={id} open={open} anchorEl={anchorEl} sx={{zIndex:100000}}>
                                <Paper sx={{width:'250px', zIndex:10000}}>
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                            <Link underline="none" href={"/services"}><ListItemText primary="Services" sx={{color: '#046A38', fontWeight:'bold'}}/></Link>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding sx={{flexDirection:'column', alignItems:'start', width:'100%'}}>
                                            <ListItemButton onClick={handleEcommerceClick} sx={{width:'100%'}}>
                                                <ListItemText primary="E-Commerce" sx={{color: '#046A38', fontWeight:'bold', width:'100%'}}/>
                                                {subMenuOpen ? <ExpandLess htmlColor={'#046A38'} /> : <ExpandMore htmlColor={'#046A38'}/>}
                                            </ListItemButton>
                                            <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding sx>
                                                        <ListItem disablePadding>
                                                            <ListItemButton sx={{ pl: 4 }}>
                                                            <Link underline="none" href={"/cashOnDelivery"}><ListItemText primary="Cash On Delivery (COD)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/cashOnDelivery' ? '3px solid #046A38' : ''}}/></Link>
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <ListItemButton sx={{ pl: 4 }}>
                                                                <Link underline="none" href={"/cashOnPickup"}><ListItemText primary="Cash On Pickup (COP)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/cashOnPickup' ? '3px solid #046A38' : ''}}/></Link>
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <ListItemButton sx={{ pl: 4 }}>
                                                            <Link underline="none" href={"/paymentOnDelivery"}><ListItemText primary="Payment On Delivery (POD)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/paymentOnDelivery' ? '3px solid #046A38' : ''}}/></Link>
                                                            </ListItemButton>
                                                        </ListItem>
                                                </List>
                                            </Collapse>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                            <Link underline="none" href={"/sendCourtDocuments"}><ListItemText primary="Court Accredited Transactions (CATs)" sx={{color: '#046A38', fontWeight:'bold'}}/></Link>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                            <Link underline="none" href={"/international"}><ListItemText primary="JRS International" sx={{color: '#046A38', fontWeight:'bold'}}/></Link>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Popper>
                        </Box>

                      

                        <Box sx={{display:'flex', flexGrow:0}}>
                            {headerRightOptions.map((opt) => (
                                <Box sx={{marginRight: '30px', height:'52px', display:'flex', alignItems:'center',  borderBottom: currentLocation === opt.route ? '5px solid #046A38' : ''}}>
                                    <Link underline="none" href={opt.route} onClick={() => scrollDownToContact(opt.name)}><Typography sx={{color:'#046A38'}}><b>{opt.name}</b></Typography></Link>
                                </Box>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>           
            </AppBar>
        </>
    )
}

export default Header;