import React from "react";
import { useLocation } from 'react-router-dom'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import DpoImage from '../../assets/dpoimage2025.jpg';




function DataPrivacy() {

    const [readMoreModalOpen, setReadMoreModalOpen] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
    }, [location.pathname]);

    const handleClose = () => {
        setReadMoreModalOpen(false);
    }

    const openReadMoreModal = () => {
        setReadMoreModalOpen(true);
    }

    return (
        <>
            <Header />
            {/* Mobile */}
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column' }}>


                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                    <img src={DpoImage} style={{ width: '200px' }} />
                </Box>

                <Box sx={{ paddingLeft: '20px', paddingRight: '20px', textAlign: 'justify', flexDirection: 'column' }}>


                    <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px', color: '#000' }}>
                        PRIVACY POLICY
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#000' }}>
                        Data Privacy Notice
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Business Corporation (“JRS Express”) recognizes the importance of protecting the Personal Data of its
                        clients worldwide and is one with the NPC in its mission to forward global standards for data privacy and
                        protection and in building a culture of privacy, through people empowerment, that enables and upholds
                        the right to privacy and supports free flow of information. JRS Express ensures that all Personal Data
                        collected by it are processed in compliance with the provisions of the Data Privacy Act of 2012 (“DPA”), its
                        IRR, and other relevant policies, including the issuances of the National Privacy Commission (“NPC”).
                    </Typography>

                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Collection of Personal Information
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        The following information shall be collected to verify identity, to perform our tasks properly, and to notify
                        customers of updates regarding their parcels:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Names of Sender and Consignee
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Addresses of Sender and Consignee
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Email Addresses of Sender and Consignee
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Contact Numbers of Sender and Consignee
                        </Typography>

                    </ol>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        JRS Express shall only collect the abovementioned personal information with the consent of the customers.
                        Should the individual refuse to provide the abovementioned personal information, JRS Express may not
                        be able to provide them of the services they may require.
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        JRS Express shall implement appropriate and reasonable organizational, physical, and technical security
                        measures for the protection of Personal Data. These security measures shall maintain the availability,
                        integrity, and confidentiality of Personal Data, and shall protect them against both natural and human
                        dangers such as but not limited to accidental loss or destruction, unlawful access, fraudulent misuse,
                        unlawful destruction, alteration, and contamination.
                    </Typography>


                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        A. Organizational Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        The following principles and guidelines shall be observed and implemented as part of organizational
                        security measures:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Protection of Privacy
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Access Protocols
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Security Awareness
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Data Protection and Risk Management
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Digital Security Policies
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Incident Response Policy
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Audit and Review
                        </Typography>
                    </ol>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Express and JRS Members shall process and hold Personal Data with utmost confidentiality. This
                        obligation continues even after the separation of JRS Members from JRS Express. The duty of confidentiality
                        for relevant Third Parties who have access to Personal Data shall be provided in the confidentiality clause
                        of their respective contracts with JRS Express.
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        B. Physical Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        The physical storage location of Personal Data are folders, envelopes, drawers, cabinets, vaults, rooms, and
                        other file storage devices and locations within the premises of JRS Express and its branches or in storage
                        facilities contracted by JRS Express which shall be kept securely and locked at all times when not in use.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>

                        Personal Data collected in digital/electronic format shall be stored in external hard disks, USB flash disks,
                        and optical disks which shall be kept securely in locked storage location when not in use.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        Only authorized JRS Employees shall be allowed to access storage locations, facilities, and devices
                        containing Personal Data. Otherwise, access may be granted upon recommendation of the DPO and
                        approval of the management upon a request with specified purpose.
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        C. Technical Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Express shall use systems, including but not limited to an intrusion detection system, not falling below
                        standards and practices in the logistics industry to monitor security breaches to its network and alert the
                        appropriate JRS Employees of any attempt to interrupt or disturb the system.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        The saving of files to portable storage devices such as external hard disks, USB flash disks, and optical
                        disks, shall be prohibited by JRS Express as much as practicable. Drives and USB ports may also be disabled
                        as a security measure. An allocated network drive shall always be preferred to saving files locally to a
                        machine. In case there is a need to save in a local machine or a portable storage device, only authorized JRS
                        Employees and not Third Parties may access such files. If there is a need to save files in portable storage
                        devices, only official portable devices encrypted with technologies not falling below industry standards
                        shall be Used
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Rights of Data Subjects
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        The following are the rights of data subjects under the Data Privacy Act of 2012:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to be Informed – Data Subjects are informed of what, why, and how their personal information
                            shall be collected and processed.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Object – Data Subjects may refuse to have their personal information collected or
                            processed. However, doing so may prevent JRS Express from providing services as such
                            personal information are necessary for conducting business.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Access – Data subjects may request access to their personal information.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Rectification – Data Subjects may correct, rectify, or update personal information if there
                            are any inaccuracies or errors.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Erasure or Blocking – Data Subjects may suspend, withdraw, or order the blocking, removal,
                            or destruction of their personal information in cases provided for under the Data Privacy Act.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Damages – Data subjects may be indemnified for damages due to inaccurate, incomplete,
                            outdated, false, unlawfully obtained, or unauthorized use of personal information.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Data Portability – Data subjects have the right to obtain a copy of data undergoing
                            processing in an electronic or structured format, which is commonly used and allows further
                            use by the data subject.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to File a Complaint – Data subjects may lodge complaints with the NPC if any data privacy
                            rights have been violated.
                        </Typography>
                    </ol>

                    {/* Consent and Acceptance of Terms and Conditions */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Consent and Acceptance of Terms and Conditions
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        By availing the services provided by JRS Express and signing the sales invoice and waybill, the customer
                        or client signifies their consent, agreement, and acceptance of the terms outlined in this privacy notice.
                        This includes consenting to the collection, use, disclosure, recording, processing, and sharing of all
                        information necessary for transacting and availing the services of JRS Express.
                    </Typography>

                    {/* Amendments to Privacy Notice */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Amendments to Privacy Notice
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Express reserves the right to change and revise this Privacy Notice as necessary and in accordance
                        with updates to the prevailing laws of Data Privacy.
                    </Typography>

                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Contact Person
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        If you have any questions or concerns regarding our parcels and services, you may contact us at:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Email Address:
                            <ol type="a">
                                <Typography variant="body2" sx={{ color: '#000' }} component="li">
                                    operations@jrs-express.com.ph
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#000' }} component="li">
                                    operations@jrsexpress.net.ph
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#000' }} component="li">
                                    customerservice@jrs-express.com.ph
                                </Typography>
                            </ol>
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Contact Numbers:
                            <ol type="a">
                                {[
                                    "09985848236", "09985848250", "09985848241", "09985864274",
                                    "09985855304", "09985848242", "09985864583", "09985848254",
                                    "09985855272", "09985864378"
                                ].map((number, index) => (
                                    <Typography key={index} variant="body2" sx={{ color: '#000' }} component="li">
                                        {number}
                                    </Typography>
                                ))}
                            </ol>
                        </Typography>
                    </ol>

                    <Typography variant="body1" sx={{ marginTop: '10px', textAlign: 'justify', color: '#000' }}>
                        If you have any questions or concerns regarding JRS Express’ Privacy Policy, you may contact us at:
                    </Typography>
                    <ul>
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Email: jrs_dpo@jrs-express.com.ph
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Contact number: 09178054894
                        </Typography>
                    </ul>
                </Box>

            </Box>


















            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, flexDirection: 'column' }}>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                    <Box sx={{ flex: 1, display: 'flex' }}>

                        <Box sx={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '30px' }}>
                            <img src={DpoImage} style={{ width: '300px' }} />
                        </Box>
                        <Box sx={{ width: '45%', paddingRight: '35px', textAlign: 'justify' }}>
                            <ul>

                                <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px', color: '#000' }}>
                                    PRIVACY POLICY
                                </Typography>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#000' }}>
                                    Data Privacy Notice
                                </Typography>

                                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                                    JRS Business Corporation (“JRS Express”) recognizes the importance of protecting the Personal Data of its
                                    clients worldwide and is one with the NPC in its mission to forward global standards for data privacy and
                                    protection and in building a culture of privacy, through people empowerment, that enables and upholds
                                    the right to privacy and supports free flow of information. JRS Express ensures that all Personal Data
                                    collected by it are processed in compliance with the provisions of the Data Privacy Act of 2012 (“DPA”), its
                                    IRR, and other relevant policies, including the issuances of the National Privacy Commission (“NPC”).
                                </Typography>

                                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                                    Collection of Personal Information
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                                    The following information shall be collected to verify identity, to perform our tasks properly, and to notify
                                    customers of updates regarding their parcels:
                                </Typography>
                                <ol type="a">
                                    <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                                        Names of Sender and Consignee
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                                        Addresses of Sender and Consignee
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                                        Email Addresses of Sender and Consignee
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                                        Contact Numbers of Sender and Consignee
                                    </Typography>

                                </ol>
                                
                                <Typography variant="body1" sx={{ marginBottom: '10px',marginTop:'20px', textAlign: 'justify', color: '#000' }}>
                                    JRS Express shall only collect the abovementioned personal information with the consent of the customers.
                                    Should the individual refuse to provide the abovementioned personal information, JRS Express may not
                                    be able to provide them of the services they may require.
                                </Typography>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                                    Security Measures
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                                    JRS Express shall implement appropriate and reasonable organizational, physical, and technical security
                                    measures for the protection of Personal Data. These security measures shall maintain the availability,
                                    integrity, and confidentiality of Personal Data, and shall protect them against both natural and human
                                    dangers such as but not limited to accidental loss or destruction, unlawful access, fraudulent misuse,
                                    unlawful destruction, alteration, and contamination.
                                </Typography>

                            </ul>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: '90%', paddingLeft: '100px', paddingRight: '100px', textAlign: 'justify', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        A. Organizational Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        The following principles and guidelines shall be observed and implemented as part of organizational
                        security measures:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Protection of Privacy
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Access Protocols
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Security Awareness
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Data Protection and Risk Management
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Digital Security Policies
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Incident Response Policy
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Audit and Review
                        </Typography>
                    </ol>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Express and JRS Members shall process and hold Personal Data with utmost confidentiality. This
                        obligation continues even after the separation of JRS Members from JRS Express. The duty of confidentiality
                        for relevant Third Parties who have access to Personal Data shall be provided in the confidentiality clause
                        of their respective contracts with JRS Express.
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        B. Physical Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        The physical storage location of Personal Data are folders, envelopes, drawers, cabinets, vaults, rooms, and
                        other file storage devices and locations within the premises of JRS Express and its branches or in storage
                        facilities contracted by JRS Express which shall be kept securely and locked at all times when not in use.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>

                        Personal Data collected in digital/electronic format shall be stored in external hard disks, USB flash disks,
                        and optical disks which shall be kept securely in locked storage location when not in use.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        Only authorized JRS Employees shall be allowed to access storage locations, facilities, and devices
                        containing Personal Data. Otherwise, access may be granted upon recommendation of the DPO and
                        approval of the management upon a request with specified purpose.
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        C. Technical Security Measures
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Express shall use systems, including but not limited to an intrusion detection system, not falling below
                        standards and practices in the logistics industry to monitor security breaches to its network and alert the
                        appropriate JRS Employees of any attempt to interrupt or disturb the system.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        The saving of files to portable storage devices such as external hard disks, USB flash disks, and optical
                        disks, shall be prohibited by JRS Express as much as practicable. Drives and USB ports may also be disabled
                        as a security measure. An allocated network drive shall always be preferred to saving files locally to a
                        machine. In case there is a need to save in a local machine or a portable storage device, only authorized JRS
                        Employees and not Third Parties may access such files. If there is a need to save files in portable storage
                        devices, only official portable devices encrypted with technologies not falling below industry standards
                        shall be Used
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Rights of Data Subjects
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        The following are the rights of data subjects under the Data Privacy Act of 2012:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to be Informed – Data Subjects are informed of what, why, and how their personal information
                            shall be collected and processed.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Object – Data Subjects may refuse to have their personal information collected or
                            processed. However, doing so may prevent JRS Express from providing services as such
                            personal information are necessary for conducting business.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Access – Data subjects may request access to their personal information.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Rectification – Data Subjects may correct, rectify, or update personal information if there
                            are any inaccuracies or errors.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Erasure or Blocking – Data Subjects may suspend, withdraw, or order the blocking, removal,
                            or destruction of their personal information in cases provided for under the Data Privacy Act.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Damages – Data subjects may be indemnified for damages due to inaccurate, incomplete,
                            outdated, false, unlawfully obtained, or unauthorized use of personal information.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to Data Portability – Data subjects have the right to obtain a copy of data undergoing
                            processing in an electronic or structured format, which is commonly used and allows further
                            use by the data subject.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000', marginBottom: '5px' }} component="li">
                            Right to File a Complaint – Data subjects may lodge complaints with the NPC if any data privacy
                            rights have been violated.
                        </Typography>
                    </ol>


                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Consent and Acceptance of Terms and Conditions
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        By availing the services provided by JRS Express and signing the sales invoice and waybill, the customer
                        or client signifies their consent, agreement, and acceptance of the terms outlined in this privacy notice.
                        This includes consenting to the collection, use, disclosure, recording, processing, and sharing of all
                        information necessary for transacting and availing the services of JRS Express.
                    </Typography>


                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Amendments to Privacy Notice
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify', color: '#000' }}>
                        JRS Express reserves the right to change and revise this Privacy Notice as necessary and in accordance
                        with updates to the prevailing laws of Data Privacy.
                    </Typography>

                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>
                        Contact Person
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', textAlign: 'justify', color: '#000' }}>
                        If you have any questions or concerns regarding our parcels and services, you may contact us at:
                    </Typography>
                    <ol type="a">
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Email Address:
                            <ol type="a">
                                <Typography variant="body2" sx={{ color: '#000' }} component="li">
                                    operations@jrs-express.com.ph
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#000' }} component="li">
                                    operations@jrsexpress.net.ph
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#000' }} component="li">
                                    customerservice@jrs-express.com.ph
                                </Typography>
                            </ol>
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Contact Numbers:
                            <ol type="a">
                                {[
                                    "09985848236", "09985848250", "09985848241", "09985864274",
                                    "09985855304", "09985848242", "09985864583", "09985848254",
                                    "09985855272", "09985864378"
                                ].map((number, index) => (
                                    <Typography key={index} variant="body2" sx={{ color: '#000' }} component="li">
                                        {number}
                                    </Typography>
                                ))}
                            </ol>
                        </Typography>
                    </ol>

                    <Typography variant="body1" sx={{ marginTop: '10px', textAlign: 'justify', color: '#000' }}>
                        If you have any questions or concerns regarding JRS Express’ Privacy Policy, you may contact us at:
                    </Typography>
                    <ul>
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Email: jrs_dpo@jrs-express.com.ph
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#000' }} component="li">
                            Contact number: 09178054894
                        </Typography>
                    </ul>
                </Box>
            </Box>

            <Footer />

        </>
    )
}

export default DataPrivacy;