import logo from './logo.svg';
import './App.css';
import Routes from "./routes";
import version from '../package.json';
import CacheBuster from 'react-cache-buster';

function App() {
  return (
    <CacheBuster
      currentVersion={version.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
    <Routes />
    </CacheBuster>
  );
}

export default App;
