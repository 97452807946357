import React from "react";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ApiService from "../../services/apiService";

function JrsCareers() {
    const navigate = useNavigate();

    const [activeCareers, setActiveCareers] = React.useState([]);

    React.useEffect(() => {
        ApiService.getActiveCareers()
            .then(response => response.json())
            .then(json => {
                console.log("careers", json);
                setActiveCareers(json);
            })

        
    }, []);

    const navigateToCareer = (id) => {
        navigate("/careers/" + id);
    }
    return (
        <>
            <Header />

            <Box sx={{display: {xs: 'flex', sm:'flex', md: 'flex'}, flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'30px'}}>

                <Typography variant="h6" gutterBottom><b>Our Job Openings</b></Typography>

            </Box>

            <Box sx={{display: {xs: 'flex', sm:'flex', md: 'none'}, flexDirection:'column', width:'80%', margin:'0 auto'}}>
                {activeCareers.map((row) => (
                    <>
                        <Box>
                            <Typography sx={{fontWeight:'bold', fontSize:'20px', marginBottom:'5px'}}>{row.jobPosition}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{fontWeight:'normal', fontSize:'20px', marginBottom:'5px'}}>{row.category}</Typography>
                        </Box>
                        <Box>
                            <Button
                                sx={{
                                    backgroundColor: "#046A38",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    marginBottom:'30px',
                                    "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                                }}
                                onClick={() => navigateToCareer(row.id)}
                                >
                                    VIEW JOB
                            </Button>
                        </Box>
                    </>
                ))}
            </Box>

            <Box sx={{display: {xs: 'none', sm:'none', md: 'flex'}, flexDirection:'column', alignItems:'center', justifyContent: 'center',padding:'40px'}}>

            <TableContainer component={"div"}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight:'bold', fontSize:'20px', color: '#046A38'}}>Job Title</TableCell>
                        <TableCell sx={{fontWeight:'bold', fontSize:'20px', color: '#046A38'}}>Category</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {activeCareers.map((row) => (
                            <TableRow
                                key={row.jobPosition}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" sx={{fontSize:'18px'}}>
                                    {row.jobPosition}
                                </TableCell>
                                <TableCell sx={{fontSize:'18px'}}>{row.category}</TableCell>
                                <TableCell sx={{textAlign:'right'}}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#046A38",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                                        }}
                                        onClick={() => navigateToCareer(row.id)}
                                        >
                                            VIEW JOB
                                        </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                

            </Box>
          
            <Footer/>
        </>
    )
}

export default JrsCareers;