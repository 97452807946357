import React from "react";
import { useLocation } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import jrsLogoMobile from '../../assets/logo_mobile.png';

function Drawer({isOpen, drawerCloseCallback}) {

    const [currentLocation, setCurrentLocation] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const location = useLocation();
    
    React.useEffect(() => {
        setCurrentLocation(location.pathname);

        if (location.pathname.indexOf('cashOnDelivery') >= 0 || location.pathname.indexOf('cashOnPickup') >= 0 || location.pathname.indexOf('paymentOnDelivery') >= 0) {
            setOpen(true);
        }
    }, [location.pathname])

    var headerOptions = [
        {
            name: "Home",
            route: "/"
        },
        {
            name: "Rates",
            route: "/rates"
        },
        {
            name: "Branch Locator",
            route: "/branches"
        },
        {
            name: "Services",
            route: "/services"
        },
        {
            name: "Scope",
            route: "/scope"
        },
        {
            name: "Careers @JRS",
            route: "/careers"
        }
    ];

    var headerRightOptions = [
        {
            name: "Court Accredited Transactions (CATs)",
            route: "/sendCourtDocuments"
        },
        {
            name: "JRS International",
            route: "/international"
        },
        {
            name: "About Us",
            route: "/about"
        },
        {
            name: "Contact Us",
            route: "#contact"
        }
    ];

    const closeDrawer = () => {
        if (drawerCloseCallback) {
            drawerCloseCallback(false);
        }
    }

    const handleClick = () => {
        setOpen(!open);
      };

    const scrollDownToContact = (isContact) => {
        if (isContact === "Contact Us") {
            if (drawerCloseCallback) {
                drawerCloseCallback(true);
            }
         
        }
    }

    return (
        <>
            <SwipeableDrawer anchor={"right"} open={isOpen} onClose={closeDrawer}>
                <Paper sx={{width:'320px', height:'100%'}}>
                    <Box sx={{display:'flex', padding:'15px'}}>
                        <Box sx={{flexGrow:1}}>
                            <img src={jrsLogoMobile} style={{width:'40%'}} />
                        </Box>
                        <Box>
                            <IconButton onClick={closeDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Divider />

                    <Box>
                        <List>
                            {headerOptions.map((opt) => (
                                <>
                                      <ListItem disablePadding>
                                            <ListItemButton>
                                                <Link underline="none" href={opt.route}><ListItemText primary={opt.name} sx={{color: '#046A38', fontWeight:'bold', borderBottom: currentLocation === opt.route ? '3px solid #046A38' : ''}}/></Link>
                                            </ListItemButton>
                                      </ListItem>
                                </>
                            ))}

                            <ListItemButton onClick={handleClick}>
                                <ListItemText primary="E-Commerce" sx={{color: '#046A38', fontWeight:'bold'}}/>
                                {open ? <ExpandLess htmlColor={'#046A38'} /> : <ExpandMore htmlColor={'#046A38'}/>}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx>
                                        <ListItem disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                            <Link underline="none" href={"/cashOnDelivery"}><ListItemText primary="Cash On Delivery (COD)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/cashOnDelivery' ? '3px solid #046A38' : ''}}/></Link>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link underline="none" href={"/cashOnPickup"}><ListItemText primary="Cash On Pickup (COP)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/cashOnPickup' ? '3px solid #046A38' : ''}}/></Link>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                            <Link underline="none" href={"/paymentOnDelivery"}><ListItemText primary="Payment On Delivery (POD)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/paymentOnDelivery' ? '3px solid #046A38' : ''}}/></Link>
                                            </ListItemButton>
                                        </ListItem>
                                        {/* <ListItem disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                            <Link underline="none" href={"/sendCourtDocuments"}><ListItemText primary="Court Accredited Transactions (CAT)" sx={{color: '#046A38', fontWeight:'bold',  borderBottom: currentLocation === '/sendCourtDocuments' ? '3px solid #046A38' : ''}}/></Link>
                                            </ListItemButton>
                                        </ListItem> */}
                                </List>
                            </Collapse>

                            {headerRightOptions.map((opt) => (
                                <>
                                      <ListItem disablePadding>
                                            <ListItemButton>
                                                <Link underline="none" href={opt.route} onClick={() => scrollDownToContact(opt.name)}><ListItemText primary={opt.name} sx={{color: '#046A38', fontWeight:'bold', borderBottom: currentLocation === opt.route ? '3px solid #046A38' : ''}}/></Link>
                                            </ListItemButton>
                                      </ListItem>
                                </>
                            ))}
                        </List>
                    </Box>
                </Paper>
            </SwipeableDrawer>
        </>
    )
}

export default Drawer;