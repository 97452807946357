import React from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import callMobile from '../../assets/call_mobile.png';
import locationMobile from '../../assets/location_mobile.png';
import jrsLogoMobile from '../../assets/logo_mobile.png';

import DpoImageSmall from '../../assets/resizedDPO2025.jpg';
import fbMobile from "../../assets/fb_mobile.png";
import ytMobile from "../../assets/yt_mobile.png";
import fb from "../../assets/fb.png";
import yt from "../../assets/yt.png";

import talkDesktop from "../../assets/talk_desktop.png";
import openDesktop from "../../assets/open_desktop.png";
import visitDesktop from "../../assets/visit_desktop.png";

function Footer() {
    return (
        <>
            {/* for mobile */}
            <Box sx={{display: { xs:'flex', sm:'flex', md:'none'}, flexDirection:'column', backgroundColor: '#fff', padding:'15px'}}>
            <Box sx={{display:'flex', padding:'15px'}} id="logo">
            <Box sx={{width:'50%'}}>
                    <img src={jrsLogoMobile} style={{width:'112px'}}/>
                    
                </Box>
                <Box sx={{width:'50%'}}>
                 
                    <Link href="/DataPrivacy" underline="none"><img src={DpoImageSmall} style={{width:'94px'}}/></Link>
                </Box>
            </Box>
           
                

                <Box sx={{display:'flex', padding:'15px'}} id="contact">
                    <Box sx={{width:'50%'}}>
                        <img src={callMobile} style={{}}/>
                        <Typography><b>Let's talk.</b></Typography>
                        <Typography>(02) 8631-7351-56</Typography>
                        <Typography>(02) 8631-7357</Typography>
                        <Typography>Careers: hrd@jrs-express.com.ph </Typography>
                        <Typography>(02) 8631-7351 to 56 loc 235,252,222 </Typography>
                        
                    </Box>
                    <Box sx={{width:'50%'}}>
                        <img src={locationMobile} style={{}}/>
                        <Typography><b>Visit us.</b></Typography>
                        <Typography>No. 19 Brixton Street</Typography>
                        <Typography>Barangay Kapitolyo,</Typography>
                        <Typography>Pasig City, Metro Manila</Typography>
                    </Box>
                </Box>

                <Box sx={{padding:'15px'}}>
                    <Divider/>
                </Box>

                <Box sx={{display:'flex', padding:'15px'}}>
                    <Box sx={{width:'50%'}}>
                        <Typography variant="h5" gutterBottom><b>Services</b></Typography>
                        <Link sx={{color: '#000'}} href="/services" underline="none"><Typography gutterBottom>Delivery Service</Typography></Link>
                        <Typography gutterBottom>Corporate Solution</Typography>
                        <Link sx={{color: '#000'}} href="https://www.csi-store.com/" underline="none"><Typography gutterBottom>JRS Office+</Typography></Link>
                    </Box>
                    <Box sx={{width:'50%'}}>
                        <Typography variant="h5" gutterBottom><b>About</b></Typography>
                        <Link href="/about#profile" underline="none"> <Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Profile</Typography></Link>
                        <Link href="/about#history" underline="none"> <Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>History</Typography></Link>
                        <Link href="/about#vision" underline="none"> <Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Vision</Typography></Link>
                        <Link href="/about#mission" underline="none"> <Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Mission</Typography></Link>
                    </Box>
                </Box>

                <Box sx={{display:'flex', padding:'15px'}}>
                    <Box sx={{width:'50%'}}>
                        <Typography variant="h5" gutterBottom><b>Coverage</b></Typography>
                        <Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>International</Typography>
                        <Link href="/branches" underline="none"> <Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Domestic</Typography></Link>
                    </Box>
                    <Box sx={{width:'50%'}}>
                        <Typography variant="h5" gutterBottom><b>Others</b></Typography>
                        <Link href="/track" underline="none"><Typography gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Track your shipment</Typography></Link>
                        <Typography gutterBottom>FAQs</Typography>
                    </Box>
                </Box>

                <Box sx={{display:'flex', justifyContent:'center', marginTop: '15px', marginBottom: '30px'}}>
                    <Link href="/rates"><Button sx={{backgroundColor: '#fff', color: '#046A38', border:'1px solid #046A38',height:'72px', width:'173px', fontWeight:'bold'}}>Get a free rate</Button></Link>
                </Box>
            </Box>
            <Box sx={{display: { xs:'flex', sm:'flex', md:'none'}, flexDirection:'column', backgroundColor: '#f4f4f4', padding:'15px'}}>
                <Typography variant="h5"><b>Follow us on our channels.</b></Typography>

                <Box sx={{display:'flex', marginTop:'15px', marginBottom:'30px'}}>
                    <Link href="https://www.facebook.com/JRS.Express.OfficialFanPage" underline="none"><img src={fbMobile} style={{}}/></Link>
                    <Link href="https://www.youtube.com/channel/UCM7Jjp4xRxNML0b_MthM0yg" underline="none"><img src={ytMobile} style={{marginLeft:'15px'}}/></Link>
                </Box>

                <Box sx={{display:'flex', marginTop:'15px', marginBottom:'60px'}}>
                    <TextField id="outlined-basic" label="Email Address" variant="outlined" sx={{width:'60%'}}/>
                    <Button sx={{backgroundColor: '#046A38', color: '#fff', marginLeft:'15px',fontWeight:'bold', width:'40%'}}>Sign me up!</Button>
                </Box>

                <Typography gutterBottom sx={{marginBottom:'20px'}}>&copy; 2023 - JRS Business Corporation</Typography>

                <Box sx={{display:'flex'}}>
                            <Link href="/Privacy" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Privacy /</Typography></Link>
                            <Link href="/TermsAndConditions" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Terms & Conditions /</Typography></Link>
                            <Link href="/" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}> Site map /</Typography></Link>
                             <Link href="/" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}> Contact.</Typography></Link>
                </Box>
            </Box>

            {/* for non mobile */}
            <Box sx={{display: { xs:'none', sm:'none', md:'flex'},  flexDirection:'column', justifyContent: 'center', padding: '30px', height:'250px'}}>
                <Box sx={{display:'flex'}}  id="contact">
                    <Box sx={{marginLeft:'30px', flex:1}}>
                        <img src={jrsLogoMobile} />
                    </Box>
                    <Box sx={{marginLeft:'30px', flex:1}}>
                    <Link href="/DataPrivacy" underline="none"><img src={DpoImageSmall} style={{}}/></Link>
                    </Box>
                    <Box sx={{display:'flex', flex:1}}>
                        <Box>
                            <img src={talkDesktop} sx={{width:'100%'}}/>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'bold'}}>Let's talk.</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>(02) 8631-7351-56</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>(02) 8631-7357</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>Careers: hrd@jrs-express.com.ph </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>(02) 8631-7351 to 56 loc 235,252,222</Typography>
                      
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', flex:1}}>
                        <Box>
                            <img src={openDesktop} sx={{width:'100%'}}/>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'bold'}}>We're open.</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>Open during</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>Sundays and Holidays</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', flex:1}}>
                        <Box>
                            <img src={visitDesktop} sx={{width:'100%'}}/>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#046A38', marginLeft: '15px', fontWeight:'bold'}}>Visit us.</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>No. 19 Brixton Street,</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>Barangay Kapitolyo,</Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: '#000', marginLeft: '15px', fontWeight:'normal'}}>Pasig City, Metro Manila</Typography>
                        </Box>
                    </Box>
                </Box>

                <Divider sx={{width:'100%', marginTop:'30px'}} />
            </Box>
            <Box sx={{display: {xs: 'none', sm:'none', md:'flex'}, justifyContent: 'center', paddingBottom:'30px'}}>
                <Box sx={{flex:1, display:'flex', justifyContent:'center'}}>
                    <Box>
                    <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Branch Locator</Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>International</Typography>
                    <Link href="/branches" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Domestic</Typography></Link>
                    </Box>
                </Box>
                <Box sx={{flex:1, display:'flex', justifyContent:'center'}}>
                    <Box>
                        <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Services</Typography>
                        <Link sx={{color: '#000'}} href="/services" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Delivery Service</Typography></Link>
                        <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Corporate Solution</Typography>
                        <Link sx={{color: '#000'}} href="https://www.csi-store.com/" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>JRS OFFICE+</Typography></Link>
                    </Box>
                </Box>
                <Box sx={{flex:1, display:'flex', justifyContent:'center'}}>
                    <Box>
                        <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>About</Typography>
                        <Link href="/about#profile" underline="none"> <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Profile</Typography></Link>
                        <Link href="/about#history" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>History</Typography></Link>
                        <Link href="/about#vision" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Vision</Typography></Link>
                        <Link href="/about#mission" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Mission</Typography></Link>
                    </Box>
                </Box>
                <Box sx={{flex:1, display:'flex', justifyContent:'center'}}>
                    <Box>
                    <Typography variant="h6" gutterBottom sx={{color: '#046A38', fontWeight: 'bold'}}>Others</Typography>
                    <Link href="/track" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Track your shipment</Typography></Link>
                    <Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>FAQs</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: { xs:'none', sm:'none', md:'flex'}, flexDirection:'column', backgroundColor: '#f4f4f4', padding:'30px'}}>

                <Box sx={{display:'flex'}}>
                    <Box sx={{flexGrow:1}}>
                        <Typography variant="h5" sx={{color: '#046A38'}}><b>Subscribe to the JRS Newsletter</b></Typography>

                        <Box sx={{display:'flex', marginTop:'15px', marginBottom:'60px'}}>
                            <TextField id="outlined-basic" label="Email Address" variant="outlined" sx={{width:'60%'}}/>
                            <Button sx={{backgroundColor: '#046A38', color: '#fff', marginLeft:'15px',fontWeight:'bold', width:'40%', '&:hover': {backgroundColor: '#046A38', color: '#fff'}}}>Sign me up!</Button>
                        </Box>
                    </Box>
                    <Box sx={{width:'30%', textAlign:'right'}}>
                        <Typography variant="h5">Follow us.</Typography>
                        <Box sx={{display:'flex', marginTop:'15px', marginBottom:'30px', justifyContent:'end'}}>
                        <Link href="https://www.facebook.com/JRS.Express.OfficialFanPage" underline="none"><img src={fb} style={{}}/></Link>
                        <Link href="https://www.youtube.com/channel/UCM7Jjp4xRxNML0b_MthM0yg" underline="none"><img src={yt} style={{marginLeft:'15px'}}/></Link>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{display:'flex'}}>
                    <Box sx={{flexGrow:1}}>
                        <Typography gutterBottom sx={{marginBottom:'20px'}}>&copy; 2024 - JRS Business Corporation</Typography>
                    </Box>
                    <Box sx={{textAlign:'right'}}>
                        <Box sx={{display:'flex', justifyContent:'end'}}>
                            <Link href="/Privacy" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Privacy /</Typography></Link>
                            <Link href="/TermsAndConditions" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}>Terms & Conditions /</Typography></Link>
                            <Link href="/" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}> Site map /</Typography></Link>
                             <Link href="/" underline="none"><Typography variant="subtitle1" gutterBottom sx={{color: '#000', fontWeight: 'normal'}}> Contact.</Typography></Link>
                        </Box>
                    </Box>
                </Box>
                

                

              

              
            </Box>
        </>
    )
}

export default Footer;