import React from "react";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Carousel from 'react-material-ui-carousel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import jrsLogoMobile from '../../assets/logo_mobile.png';
import bgImageOneMobile from '../../assets/bg_image_one.png';
import domesticBgMobile from '../../assets/domestic_bg_mobile.png';
import internationalBgMobile from '../../assets/international_bg_mobile.png';
import mapBgMobile from '../../assets/map_mobile.png';

import bgImageOne from "../../assets/bg_image_one_desktop.png";
import freeQuote from "../../assets/5.png";
import desktopMap from "../../assets/desktop_map.png";
import deliveryServiceDesktop from "../../assets/delivery_service_desktop.png";
import corporateSolutionDesktop from "../../assets/corporate_solution_desktop.png";
import jrsOfficeDesktop from "../../assets/jrs_office_desktop.png";

import deliveryService from '../../assets/delivery_service.png';
import corporateSolution from '../../assets/corporate_solution.png';
import jrsOffice from '../../assets/jrs_office.png';

import imageOne from "../../assets/Carousel/image_one.png";
import imageTwo from "../../assets/Carousel/image_two.png";
import imageThree from "../../assets/Carousel/image_three.png";
import imageFour from "../../assets/Carousel/image_four.png";
import trackImage from "../../assets/track_image.jpg";
import CookieConsent from "react-cookie-consent";

import ApiService from "../../services/apiService";

function Item(props) {

    return (
        <Box sx={{ minHeight: '300px', display: 'flex', flexDirection: 'column', width: '100%', backgroundImage: `url(${props.item.backgroundImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginBottom: 'auto' }}>

        </Box>
    )
}

function MobileItem(props) {

    return (
        <Box sx={{ minHeight: '200px', display: 'flex', flexDirection: 'column', width: '100%', backgroundImage: `url(${props.item.backgroundImage})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', marginBottom: 'auto' }}>

        </Box>
    )
}

function DesktopItem(props) {
    return (
        <Box sx={{ height: '550px', display: 'flex', flexDirection: 'column', width: '100%', backgroundImage: `url(${props.item.backgroundImage})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', marginBottom: 'auto' }}>
        </Box>
    )
}

function Main() {

    const [orNumber, setOrNumber] = React.useState("");
    const [branchCode, setBranchCode] = React.useState("");

    const [branches, setBranches] = React.useState([]);

    React.useEffect(() => {
        ApiService.getBranches()
            .then(response => response.json())
            .then(json => {
                setBranches(json);
            })
    }, [])

    var mobileItems = [
        {
            backgroundImage: imageOne
        },
        {
            backgroundImage: imageTwo
        },
        {
            backgroundImage: imageThree
        },
        {
            backgroundImage: imageFour
        }
    ];

    var desktopItems = [
        {
            backgroundImage: imageOne
        },
        {
            backgroundImage: imageTwo
        },
        {
            backgroundImage: imageThree
        },
        {
            backgroundImage: imageFour
        }
    ];

    const goToDomestic = () => {
        window.location.href = "/services?type=domestic";
    }

    const goToInternational = () => {
        window.location.href = "/services?type=international";
    }

    const goToRates = () => {
        window.location.href = "/rates";
    }

    const track = () => {
        window.location.href = "/track?or=" + orNumber;
    }

    const routeToBranch = (event, values) => {
        if (values) {
            window.location.href = "/branches?loc=" + values.id;
        }

    }
    return (
        
        <>
            <Header />

            {/* for mobile */}
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '100%' }}>
                <Carousel
                    sx={{ width: '100%', display: { xs: 'none', sm: 'block', md: 'none' } }}
                    indicators={false}
                >
                    {
                        mobileItems.map((item, i) => <Item key={i} item={item} />)
                    }
                </Carousel>

                <Carousel
                    sx={{ width: '100%', display: { xs: 'block', sm: 'none', md: 'none' } }}
                    indicators={false}
                >
                    {
                        mobileItems.map((item, i) => <MobileItem key={i} item={item} />)
                    }
                </Carousel>

                <Box sx={{ paddingTop: '15px', paddingLeft: '15px', paddingRight: '15px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Track your shipment</Typography>
                    <TextField id="outlined-basic" label="Tracking Number"  placeholder="ex.123456-123" variant="outlined" sx={{ marginTop: '15px', marginBottom: '15px' }} value={orNumber} onChange={(e) => setOrNumber(e.target.value)} />
                    <Typography variant="subtitle1" sx={{ color: '#8f8f8f', fontSize: '18px' }}>Find your tracking number</Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '30px' }}>
                        <Button sx={{ backgroundColor: '#046A38', color: '#fff', height: '60px', width: '150px', fontWeight: 'bold', '&:hover': { backgroundColor: '#046A38', color: '#fff' } }} startIcon={<SearchIcon />} onClick={track}>Track</Button>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <Box sx={{ marginBottom: '5px' }}>
                            <Typography variant="h4" gutterBottom>Our <b>Services</b></Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Divider sx={{ border: '1px solid #046A38', width: '60px' }} />
                        </Box>
                        <Box sx={{ marginTop: '30px', marginBottom: '15px' }}>
                            <img src={deliveryService} style={{ width: '112px' }} />
                        </Box>
                        <Box sx={{ paddingLeft: '30px', paddingRight: '30px', textAlign: 'center', marginBottom: '15px' }}>
                            <Link sx={{ color: '#000' }} href="/services" underline="none"><Typography variant="h5" sx={{ lineHeight: '20px', fontWeight: 'normal' }}>Delivery Service</Typography></Link>
                        </Box>
                        <Box sx={{ paddingLeft: '30px', paddingRight: '30px', textAlign: 'left' }}>
                            <Typography variant="subtitle1" sx={{ lineHeight: '20px' }}>JRS is the leading courier company when it comes to next-day delivery service anywhere in the country.</Typography>
                        </Box>
                        <Box sx={{ marginTop: '30px', marginBottom: '15px' }}>
                            <img src={corporateSolution} style={{ width: '112px' }} />
                        </Box>
                        <Box sx={{ paddingLeft: '30px', paddingRight: '30px', textAlign: 'center', marginBottom: '15px' }}>
                            <Typography variant="h5" sx={{ lineHeight: '20px', fontWeight: 'normal' }}>Corporate Solution</Typography>
                        </Box>
                        <Box sx={{ paddingLeft: '30px', paddingRight: '30px', textAlign: 'left' }}>
                            <Typography variant="subtitle1" sx={{ lineHeight: '20px' }}>Let us help you grow, whether you are a small, medium or large enterprise JRS Express can provide business solutions that will fit your needs.</Typography>
                        </Box>
                        <Box sx={{ marginTop: '30px', marginBottom: '15px' }}>
                            <img src={jrsOffice} style={{ width: '112px' }} />
                        </Box>
                        <Box sx={{ paddingLeft: '30px', paddingRight: '30px', textAlign: 'center', marginBottom: '15px' }}>
                            <Link sx={{ color: '#000' }} href="https://www.csi-store.com/" underline="none"><Typography variant="h5" sx={{ lineHeight: '20px', fontWeight: 'normal' }}>JRS Office +</Typography></Link>
                        </Box>
                        <Box sx={{ paddingLeft: '30px', paddingRight: '30px', textAlign: 'left' }}>
                            <Typography variant="subtitle1" sx={{ lineHeight: '20px' }}>JRS Express in partnership with Computer Store Inc. provides graphic layout, computer services and more. Available on selected branches nationwide.</Typography>
                        </Box>

                        <Box sx={{ marginBottom: '5px', marginTop: '30px' }}>
                            <Link sx={{ color: '#000' }} href="/services" underline="none"><Typography variant="subtitle1" gutterBottom><b>ALL SERVICES</b></Typography></Link>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                            <Divider sx={{ border: '1px solid #000', width: '60px' }} />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ backgroundImage: `url(${freeQuote})`, display: 'flex', height: '500px', backgroundSize: 'cover', justifyContent: 'center', alignItems: 'center' }}>
                    <Button sx={{ backgroundColor: '#046A38', color: '#fff', height: '72px', width: '245px', fontWeight: 'bold' }} onClick={goToDomestic}>DOMESTIC</Button>
                </Box>
                <Box sx={{ backgroundImage: `url(${internationalBgMobile})`, display: 'flex', height: '500px', backgroundSize: 'cover', justifyContent: 'center', alignItems: 'center' }}>
                    <Button sx={{ backgroundColor: '#046A38', color: '#fff', height: '72px', width: '245px', fontWeight: 'bold' }} onClick={goToInternational}>INTERNATIONAL</Button>
                </Box>
                <Box sx={{ backgroundImage: `url(${mapBgMobile})`, display: 'flex', height: '500px', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', justifyContent: 'center' }}>
                    <Paper sx={{ width: '90%', height: '150px', marginTop: '30px', padding: '10px', marginTop: '45px' }}>
                        <Typography variant="h6"><b>450 Branches Nationwide.</b></Typography>
                        <Typography variant="h6"><b>Visit the branch near you!</b></Typography>

                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            options={branches}
                            getOptionLabel={option => option.name}
                            onChange={routeToBranch}
                            sx={{ backgroundColor: '#fff' }}
                            renderInput={(params) => <TextField {...params} label="" />}
                        />
                    </Paper>
                </Box>
            </Box>

            {/* for non mobile */}
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, width: '100%', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                    <Box sx={{ marginRight: '30px' }}>
                        <Typography variant="h5"><b>Track your shipment</b></Typography>
                    </Box>
                    <Box sx={{ marginRight: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '25px' }}>
                        <TextField id="outlined-basic" label="Tracking Number" variant="outlined"  placeholder="ex.123456-123" sx={{ width: '420px' }} value={orNumber} onChange={(e) => setOrNumber(e.target.value)} />
                        <Typography variant="subtitle1" sx={{ color: '#8f8f8f', fontSize: '15px' }}>Find your tracking number</Typography>
                    </Box>
                    <Box>
                        <Button sx={{ backgroundColor: '#046A38', color: '#fff', height: '60px', width: '150px', fontWeight: 'bold', '&:hover': { backgroundColor: '#046A38', color: '#fff' } }} startIcon={<SearchIcon />} onClick={track}>Track</Button>
                    </Box>
                </Box>

                <Carousel
                    sx={{ width: '100%' }}
                    indicators={false}
                >
                    {
                        desktopItems.map((item, i) => <DesktopItem key={i} item={item} />)
                    }
                </Carousel>

                <Box sx={{ display: 'flex', padding: '15px' }}>
                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>Get a free Quote</Typography>
                        <Button sx={{ backgroundColor: '#fff', color: '#046A38', border: '1px solid #046A38', height: '72px', width: '334px', fontWeight: 'bold' }} onClick={goToRates}>Rate Calculator</Button>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <img src={freeQuote} style={{ width: '100%' }} />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', padding: '15px' }}>
                    <Box sx={{ width: '55%', backgroundImage: `url(${desktopMap})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '500px' }}>
                    </Box>
                    <Box sx={{ width: '45%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Paper sx={{ width: '90%', height: '150px', marginTop: '15px', padding: '12px', marginTop: '45px', backgroundColor: '#046A38' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}><b>450 Branches Nationwide.</b></Typography>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Find a branch near you!</Typography>

                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="combo-box-demo"
                                options={branches}
                                getOptionLabel={option => option.name}
                                onChange={routeToBranch}
                                sx={{ backgroundColor: '#fff' }}
                                renderInput={(params) => <TextField {...params} label="" />}
                            />

                        </Paper>
                    </Box>

                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#f5f5f5', padding: '30px' }}>
                    <Typography variant="h4" gutterBottom><b>Our Services</b></Typography>

                    <Box sx={{ display: 'flex' }}>
                        <Paper sx={{ padding: '30px', width: '200px', textAlign: 'center', marginRight: '30px' }}>
                            <img src={deliveryServiceDesktop} style={{ width: '112px' }} />

                            <Link href="/services" underline="none"><Typography variant="h6" gutterBottom sx={{ textDecoration: 'underline', color: '#046A38', fontWeight: 'bold' }}>Delivery Service</Typography></Link>

                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="subtitle1" gutterBottom>JRS is the leading courier company when it comes to next-day delivery service anywhere in the country.</Typography>
                            </Box>

                        </Paper>

                        <Paper sx={{ padding: '30px', width: '200px', textAlign: 'center', marginRight: '30px' }}>
                            <img src={corporateSolutionDesktop} style={{ width: '112px' }} />

                            <Typography variant="h6" gutterBottom sx={{ textDecoration: 'underline', color: '#046A38', fontWeight: 'bold' }}>Corporate Solution</Typography>

                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="subtitle1" gutterBottom>Let us help you grow, whether you are a small, medium or large enterprise JRS Express can provide business solutions that will fit your needs.</Typography>
                            </Box>

                        </Paper>

                        <Paper sx={{ padding: '30px', width: '200px', textAlign: 'center' }}>
                            <img src={jrsOfficeDesktop} style={{ width: '112px' }} />

                            <Link href="https://www.csi-store.com/" underline="none"><Typography variant="h6" gutterBottom sx={{ textDecoration: 'underline', color: '#046A38', fontWeight: 'bold' }}>JRS OFFICE+</Typography></Link>

                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="subtitle1" gutterBottom>JRS Express in partnership with Computer Store Inc. provides graphic layout, computer services and more. Available on selected branches nationwide.</Typography>
                            </Box>

                        </Paper>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
                        <Link href="/services" underline="none"><Typography variant="subtitle1" gutterBottom sx={{ color: '#046A38', fontWeight: 'bold' }}>VIEW ALL SERVICES</Typography></Link>

                        <Divider sx={{ border: '1px solid #046A38', width: '100px' }} />

                    </Box>
                </Box>
            </Box>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="myCookieConsent"
                style={{ background: '#333' }}
                buttonStyle={{ background:'#046A38', color: '#fff', fontSize: '13px' }}
            >
                This website uses cookies to enhance the user experience.  <Link href="/privacy" underline="none"><Typography variant="subtitle1" gutterBottom sx={{ color: '#fff', fontSize: '13px' }}>Learn More...</Typography></Link>
            </CookieConsent>
            <Footer />

        </>
    )
}

export default Main;